import Masks from './masks';
import RegExp from './regExp';
import Inputs from './inputs';
import Analytics from './analytics';

export default {
  Masks,
  RegExp,
  Inputs,
  Analytics,
};
