import { combineReducers } from 'redux';

import VariablesReducer from './common/variables';
import AuthReducer from './auth/auth';
import ResizeReducer from './common/resize';
import PopupReducer from './common/popup';
import FirstLoadReducer from './common/firstLoad';
import ContentReducer from './content';
import VideoPopupReducer from './common/videoPopup';
import SearchReducer from './common/search';
import lodaerSearchReducer from './common/lodaer_search';
import ChatWindowReducer from './common/openChat';

export default combineReducers({
  VariablesReducer,
  AuthReducer,
  ResizeReducer,
  PopupReducer,
  FirstLoadReducer,
  ContentReducer,
  VideoPopupReducer,
  SearchReducer,
  lodaerSearchReducer,
  ChatWindowReducer,
});
