import {
  createReducer,
  createHandlers,
} from '../reducerCreator';

import { ActionsConstants } from '../../../constants';

const initialState = {
  url: '',
  isOpen: false,
};

const handlers = createHandlers({
  [ActionsConstants.VideoPopup.OPEN_VIDEO_POPUP]: (state, url, isOpen) => ({
    ...state,
    url,
    isOpen,
  }),
  [ActionsConstants.VideoPopup.CLOSE_VIDEO_POPUP]: (state, url, isOpen) => ({
    ...state,
    url,
    isOpen,
  }),
});

export default (state = initialState, action) => createReducer(state, action, handlers);
