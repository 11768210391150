import React from 'react';
import './videoPopup.scss';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import {
  VideoPopupActions,
} from '../../redux/actions';

class VideoPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  closePopup = () => {
    // eslint-disable-next-line no-unused-vars
    const {url: {videoUrl}, closePopup} = this.props;
    closePopup();
  }

  render() {
    const {url: {videoUrl, isOpen}} = this.props;
    return (
      <>
        <div
          className={videoUrl ? 'videoPopup show' : 'videoPopup'}
          onClick={() => this.closePopup()}
        >
          <div className="popup_content">{ReactHtmlParser(videoUrl)}</div>
        </div>
        { isOpen && !videoUrl
        && (
          <div className={isOpen ? 'emptyUrl show' : 'empty'} onClick={() => this.closePopup()}>
            К сожалению, данное видео отсутствует
          </div>
        )}
      </>
    );
  }
}

export default connect(
  (state) => ({
    url: state.VideoPopupReducer.url,
    isOpen: state.VideoPopupReducer.isOpen,
  }),
  (dispatch) => ({
    openPopup: (url) => {
      dispatch(VideoPopupActions.openVideoPopup(url));
    },
    closePopup: () => {
      dispatch(VideoPopupActions.closeVideoPopup());
    },
  }),
)(VideoPopup);
