import React from 'react';
import PropTypes from 'prop-types';

const InputLabel = ({ inputId, text }) => (
  <label className='input-label' htmlFor={inputId}>{text}</label>
);

InputLabel.propTypes = {
  inputId: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default InputLabel;
