import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';
import { VideoPopupActions } from '../../redux/actions';
// Cтили
import './expertModal.scss';

const ref = React.createRef();

function ExpertModal(props) {
  const block = document.getElementsByClassName('page_main_footer_wrapper');

  useEffect(() => {
    block[0].scrollTop = 0;
  }, []);

  const { experts, expertsTitle, openPopup } = props;

  const { isMobile } = props;

  // Параметр url
  const { id } = useParams();

  // Поиск нужного эксперта
  const expert = experts.find((item) => (item.id === +id));

  // Массив без текущего эксперта
  const otherExprets = experts.map((item) => (item.id === +id ? null : item));

  useEffect(() => {
    block[0].scrollTop = 0;
    // console.dir(block);
  }, [otherExprets]);

  // Фото эксперта
  const expertPhoto = () => {
    const expertPhotoSlides = expert.gallery.map(({ image }) => (
      <SwiperSlide>
        <div className="expert-photo" style={{ backgroundImage: `url(${image})` }} />
      </SwiperSlide>
    ));
    return (
      <Swiper
        className="expertPhotoSwiper"
        id="interviewSwiper"
        setWrapperSize="true"
        breakpoints={{
          320: {
            slidesPerView: 1,
          },
          1200: {
            slidesPerView: 1.8,
            spaceBetween: 100,
          },
          1500: {
            slidesPerView: 3.5,
            spaceBetween: 270,
          },
          1700: {
            slidesPerView: 4.5,
            spaceBetween: 300,
          },
        }}
        loop
        spaceBetween={isMobile ? 30 : 50}
        freeMode={!isMobile && true}
        navigation={isMobile ? false : {
          nextEl: '.next-button',
          prevEl: '.prev-button',
        }}
        pagination={isMobile && true}
      >
        {expertPhotoSlides}
      </Swiper>
    );
  };

  // Создание слайдов других экспертов
  const otherExpertSlides = () => {
    const slider = [];
    for (let i = 0; i <= otherExprets.length; i++) {
      if (otherExprets[i]) {
        slider.push(
          <Link to={`/petruha-online/experts/${otherExprets[i].id}`} className="expert_slider">
            <div className='slider_image' style={{ backgroundImage: `url(${otherExprets[i].photo}` }} />
            <div className="expert_name">
              <p>
                {otherExprets[i].first_name} <br />
                {otherExprets[i].second_name}
              </p>
              <p className='profession'>
                {otherExprets[i].profession}
              </p>
            </div>
          </Link>,
        );
      }
    }
    return slider.map((item) => (
      <SwiperSlide>
        <ul className='experts-slider__slide'>
          {item}
        </ul>
      </SwiperSlide>
    ));
  };

  const root = document.getElementById('root');

  root.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });

  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });

  return (
    <div ref={ref} className='wrapper__modal-window_expert'>
      <container className='main-expert_container'>
        <div className='b__container'>
          <div className='backLink'>
            <Link
              to="/petruha-online"
              onClick={() => {
                document.getElementById('experts').scrollIntoView({ behavior: 'smooth' });
              }}
            />
          </div>
          <div className='container__header'>
            <div className='header__about'>
              <h1>
                {expert.first_name} <br />
                {expert.second_name}
              </h1>
              <p>{expert.profession}</p>
            </div>
            <p className="header__message">
              {ReactHtmlParser(expert.description)}
            </p>
          </div>
          <div className='container__quote'>
            <div className="quote">
              <blockquote>
                <div className="quotes" />
                {ReactHtmlParser(expert.interview)}
              </blockquote>
            </div>
            <div
              className="b_image"
              style={{ backgroundImage: `url(${expert.video_preview}` }}
              onClick={(e) => {
                e.stopPropagation();
                if (expert.video) {
                  openPopup(expert.video);
                }
              }}
            >
              {
                expert.video ? (
                  <div className="play-icon" />
                ) : null
              }
            </div>
          </div>
          <div className="container__photos">
            <div className="experts-photos__wrapper">
              {expertPhoto()}
              {
                !isMobile && (
                  <div className='nav-btn__wrapper'>
                    <div className='prev-button nav-btn' />
                    <div className='next-button nav-btn' />
                  </div>
                )
              }
            </div>
          </div>
          <div className="container__experts">
            <header>
              <h2>{expertsTitle}</h2>
            </header>
            <div className='experts-slider'>
              <Swiper
                className="interviewSwiper"
                id="interviewSwiper"
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                  },
                  1200: {
                    slidesPerView: 3.75,
                  },
                  1500: {
                    slidesPerView: 4,
                  },
                  1700: {
                    slidesPerView: 4.5,
                  },
                }}
                loop
                freeMode={!isMobile && true}
                pagination={otherExprets.length - 1 > 3 && isMobile ? { clickable: true } : ''}
              >
                {otherExpertSlides()}
              </Swiper>
            </div>
          </div>
        </div>
      </container>
      <Link
        onClick={() => {
          document.getElementById('experts').scrollIntoView({ behavior: 'smooth' });
        }}
        to="/petruha-online"
        className='modal-window__background'
      />
    </div>
  );
}

export default connect(
  (state) => ({
    isMobile: state.ResizeReducer.isMobile,
    content: state.ContentReducer.content,
  }),
  (dispatch) => ({
    openPopup: (url) => {
      dispatch(VideoPopupActions.openVideoPopup(url));
    },
  }),
)(ExpertModal);
