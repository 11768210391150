import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import BasePageLayout from '../../../components/layouts/BasePageLayout';
import PetruhaMKIPageContent from '../../../components/PetruhaMKIPageContent';
import SEO from '../../../components/SEO';

const PetruhaMKIPage = ({isMobile, lang}) => {
  const titleRu = 'Мясо-колбасные изделия от Петруха';
  const titleEn = 'Meat and sausage products — sausages from Petruha';

  useEffect(() => {
    document.title = lang === 'en' ? titleEn : titleRu;
  });
  return (
    <div>
      <BasePageLayout
        layoutBackground='white'
        sideMenuTheme='white'
        footerTheme={isMobile ? '' : 'white'}
        asideTheme={isMobile ? 'green' : 'white'}
        asideBackground='#dcdbdb'
        footerBackground='#dcdbdb'
        mobileMenuLinesColor={isMobile ? '#FFFFFF' : '#564656'}
        mobileMenuColor={isMobile ? 'transparent' : 'rgba(86, 70, 86, 0.1)'}
      >
        <SEO
          description={lang === 'en' ? titleEn : titleRu}
          ogDescription={lang === 'en' ? titleEn : titleRu}
          keywords='Бренд, Петруха МКИ, продукция'
          ogTitle={lang === 'en' ? titleEn : titleRu}
          title={lang === 'en' ? titleEn : titleRu}
        />
        <PetruhaMKIPageContent/>
      </BasePageLayout>
    </div>
  );
};

export default connect(
  (state) => ({
    isMobile: state.ResizeReducer.isMobile,
  }),
)(PetruhaMKIPage);
