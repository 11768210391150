import React from 'react';

import './basepagelayout.scss';
import { connect } from 'react-redux';
import Aside from '../../Aside';
import Footer from '../../Footer';
import BurgerMenu from '../../BurgerMenu';
import SEO from '../../SEO';

import { ChatWindowAction } from '../../../redux/actions';

class BasePageLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      color = 'gray',
      children,
      overflowProp,
      displayProp,
      marginProp,
      mobileMenuColor,
      mobileMenuLinesColor,
      layoutBackground,
      asideBackground,
      footerBackground,
      footerTheme,
      asideTheme,
      hideBurgerMenu,
      sideMenuTheme,
      isChatOpen,
      changeWindowState,
      isMobile,
      content: {
        mainMenu,
        brandsMenu,
      } = {},
    } = this.props;

    return (
      <div
        className={`page page_color_${color}`}
        style={{background: layoutBackground}}
      >
        <SEO/>
        { !hideBurgerMenu
          && (<BurgerMenu isMobile={isMobile} mobileMenuColor={mobileMenuColor} mobileMenuLinesColor={mobileMenuLinesColor} sideMenuTheme={sideMenuTheme} menuItems={mainMenu} brandItems={brandsMenu}/>) }
        <Aside asideBackground={asideBackground} asideTheme={asideTheme}/>
        <div
          className="page_main_footer_wrapper"
          style={{
            overflowY: overflowProp, display: displayProp, marginBottom: marginProp,
          }}
        >
          {window.innerWidth < 500 ? (
            <>
              {!isChatOpen ? (<>{children}</>) : (
                <div className='free-place'>
                  <div
                    onClick={() => {
                      if (window.botmanChatWidget.widget.state.isChatOpen) {
                        window.botmanChatWidget.close();
                        changeWindowState(false);
                      }
                    }}
                    className='free-place__header'
                  />
                </div>
              )}
            </>
          ) : (<>{children}</>)}
          <Footer footerBackground={footerBackground} footerTheme={footerTheme}/>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    content: state.ContentReducer.content,
    isChatOpen: state.ChatWindowReducer.isChatOpen,
    isMobile: state.ResizeReducer.isMobile,
  }),
  (dispatch) => ({
    changeWindowState: (chatWindowState) => {
      dispatch(ChatWindowAction.chatWindowChangeState(chatWindowState));
    },
  }),
)(BasePageLayout);
