import {
  createReducer,
  createHandlers,
} from '../reducerCreator';

import { ActionsConstants } from '../../../constants';

const initialState = {
  getSearchResultInProcess: true,
  result: {},
};

const handlers = createHandlers({
  [ActionsConstants.Search.SEARCH_RESULT_LOADING_IN_PROCESS]: (state, { getSearchResultInProcess }) => ({
    ...state,
    getSearchResultInProcess,
  }),
  [ActionsConstants.Search.SEARCH_RESULT_LOADING_SUCCESS]: (state, {
    result,
  }) => ({
    ...state,
    getSearchResultInProcess: false,
    result,
  }),
  [ActionsConstants.Search.SEARCH_RESULT_LOADING_FAILED]: (state) => ({
    ...state,
    result: {},
    getSearchResultInProcess: false,
  }),
});

export default (state = initialState, action) => createReducer(state, action, handlers);
