import React from 'react';

const TranslateProviderContext = (() => {
  let context = null;

  const init = (language) => React.createContext({ language });

  return {
    get: () => context,
    init: (language) => {
      context = init(language);
    },
  };
})();

export default TranslateProviderContext;
