import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';
import { VideoPopupActions } from '../../redux/actions';
// Cтили
import './interviewModal.scss';

const ref = React.createRef();

function InterviewModal(props) {
  const block = document.getElementsByClassName('page_main_footer_wrapper');

  useEffect(() => {
    block[0].scrollTop = 0;
  }, []);

  const { interviews } = props;

  // Параметр url
  const { id } = useParams();

  // Поиск нужного интервью
  const interview = interviews.find((item) => (item.id === +id));

  useEffect(() => {
    block[0].scrollTop = 0;
  });

  const root = document.getElementById('root');

  root.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });

  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });

  return (
    <div ref={ref} className='wrapper__modal-window_interview'>
      <container className='main-interview_container'>
        <div className='b__container'>
          <div className='backLink'>
            <Link
              to="/petruha-online#interviews"
              onClick={() => {
                document.getElementById('interviews').scrollIntoView({ behavior: 'smooth' });
              }}
            />
          </div>
          <div className='container__header'>
            <div className='header__about'>
              <div
                className="header__image"
                style={interview.interview_image ? { backgroundImage: `url(${interview.interview_image}` } : { display: 'none' }}
              />
              <div className="header__wrap-text">
                <h1>
                  {interview.first_name} <br />
                  {interview.second_name}
                </h1>
                <p>{interview.profession}</p>
              </div>
            </div>
            <p className="header__message">
              {ReactHtmlParser(interview.text)}
            </p>
          </div>
        </div>
      </container>
      <Link
        onClick={() => {
          document.getElementById('interviews').scrollIntoView({ behavior: 'smooth' });
        }}
        to="/petruha-online#interviews"
        className='modal-window__background'
      />
    </div>
  );
}

export default connect(
  (state) => ({
    isMobile: state.ResizeReducer.isMobile,
    content: state.ContentReducer.content,
  }),
  (dispatch) => ({
    openPopup: (url) => {
      dispatch(VideoPopupActions.openVideoPopup(url));
    },
  }),
)(InterviewModal);
