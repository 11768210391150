import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import BasePageLayout from '../../../components/layouts/BasePageLayout';
import PetruhaMasterContent from '../../../components/PetruhaMasterContent';
import SEO from '../../../components/SEO';

const PetruhaPage = ({isMobile, lang}) => {
  const titleRu = 'Петруха Мастер — охлажденные изделия из мяса птицы';
  const titleEn = 'Petruha Master — chilled poultry meat products';
  useEffect(() => {
    document.title = lang === 'en' ? titleEn : titleRu;
  });
  return (
    <div>
      <BasePageLayout
        layoutBackground='linear-gradient(106.05deg, #1A1A1A 7.77%, #45494E 93.8%)'
        asideBackground='transparent'
        footerBackground='transparent'
        footerTheme={isMobile ? '' : ''}
        mobileMenuColor={isMobile ? '' : '#9696962E'}
        mobileMenuLinesColor='#ffffff'
      >
        <SEO
          description={lang === 'en' ? titleEn : titleRu}
          ogDescription={lang === 'en' ? titleEn : titleRu}
          keywords='Бренд, Петруха Мастер, продукция'
          ogTitle={lang === 'en' ? titleEn : titleRu}
          title={lang === 'en' ? titleEn : titleRu}
        />
        <PetruhaMasterContent contentBackground='transparent'/>
      </BasePageLayout>
    </div>
  );
};

export default connect(
  (state) => ({
    isMobile: state.ResizeReducer.isMobile,
  }),
)(PetruhaPage);
