import {
  createReducer,
  createHandlers,
} from '../reducerCreator';

import { ActionsConstants } from '../../../constants';

const initialState = {
  firstLoad: true,
};

const handlers = createHandlers({
  [ActionsConstants.FirstLoad.FIRST_LOAD]: (state, firstLoad) => ({
    ...state,
    firstLoad,
  }),
});

export default (state = initialState, action) => createReducer(state, action, handlers);
