import createAction from '../actionCreator';

import { ActionsConstants } from '../../../constants';

const resize = (width, height) => (dispatch) => dispatch(createAction(
  ActionsConstants.Resize.RESIZE,
  {
    width,
    height,
    isMobile: width < 1200,
  },
));

export default {
  resize,
};
