import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {Pagination} from 'swiper';
import './online.scss';

SwiperCore.use([Pagination]);

const OnlineSlider = ({productionStages, title}) => (
  <div className="swiperContainer">
    <Swiper
      className="secondColNewsSwiper"
      pagination={{ clickable: true }}
      id="secondColNewsSwiper"
      loop="true"
    >
      {
        productionStages.map((item) => (
          <SwiperSlide key={`production-stages-${item.id}`}>
            <div className="mainWrapper_col_second_online_slider" id={`${item.id}`}>
              <div className="book-icon"/>
              <div className="header">{title}</div>
              <div className="text_content">
                <div className="subheader">{item.title}</div>
                <div className="text">{item.text}</div>
              </div>
            </div>
          </SwiperSlide>
        ))
      }
    </Swiper>
  </div>
);

export default OnlineSlider;
