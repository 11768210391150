export default {
  CHANGE_USER_INFO: 'CHANGE_USER_INFO',

  SIGN_OUT: 'SIGN_OUT',

  SIGN_UP_IN_PROCESS: 'SIGN_UP_IN_PROCESS',
  SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
  SIGN_UP_FAILED: 'SIGN_UP_FAILED',

  SIGN_IN_WITH_COOKIE_IN_PROCESS: 'SIGN_IN_WITH_COOKIE_IN_PROCESS',
  SIGN_IN_WITH_COOKIE_SUCCESS: 'SIGN_IN_WITH_COOKIE_SUCCESS',
  SIGN_IN_WITH_COOKIE_FAILED: 'SIGN_IN_WITH_COOKIE_FAILED',

  SIGN_IN_WITH_PASSWORD_IN_PROCESS: 'SIGN_IN_WITH_PASSWORD_IN_PROCESS',
  SIGN_IN_WITH_PASSWORD_SUCCESS: 'SIGN_IN_WITH_PASSWORD_SUCCESS',
  SIGN_IN_WITH_PASSWORD_FAILED: 'SIGN_IN_WITH_PASSWORD_FAILED',
};
