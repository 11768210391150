import {
  createStore,
  applyMiddleware,
} from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import allReducers from '../redux/reducers';

const ReduxStoreService = (() => {
  let instance = null;

  const init = () => {
    const store = process.env.NODE_ENV === 'production'
      ? createStore(allReducers, applyMiddleware(thunk))
      : createStore(allReducers, composeWithDevTools(applyMiddleware(thunk)));

    return {
      store,
    };
  };

  return {
    getInstance: () => {
      if (!instance) {
        instance = init();
      }
      return instance;
    },
  };
})();

export default ReduxStoreService;
