import { ActionsConstants } from '../../../constants';

const loadFirst = (firstLoad) => (dispatch) => {
  dispatch({
    type: ActionsConstants.FirstLoad.FIRST_LOAD,
    payload: firstLoad,
  });
};

export default {
  loadFirst,
};
