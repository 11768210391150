export default {
  FIELD_IS_REQUIRED: 'This field is required',

  DATE_ERROR: 'Invalid date format',
  EMAIL_FORMAT_INVALID_ERROR: 'Invalid Email format',
  NAME_FORMAT_INVALID_ERROR: 'Invalid Name format',
  EMAIL_ALREADY_EXISTS: 'User with this Email already exists',
  PASSWORD_FORMAT_INVALID_ERROR: 'Password must be at least 8 characters long and consist of upper and lower case Latin characters and numbers',
  REPEAT_PASSWORD_ERROR: 'The passwords entered do not match',

  NAME_MAX_LENGTH: 30,
};
