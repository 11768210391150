import React, {useEffect} from 'react';
import BasePageLayout from '../../../components/layouts/BasePageLayout';
import PetruhaBrandContent from '../../../components/PetruhaBrandContent';
import SEO from '../../../components/SEO';

const PetruhaPage = (lang) => {
  const titleRu = 'Петруха — базовые продукты из мяса птицы';
  const titleEn = 'Petruha - basic products from poultry meat';

  useEffect(() => {
    document.title = lang === 'en' ? titleEn : titleRu;
  });
  return (
    <div>
      <BasePageLayout
        layoutBackground='linear-gradient(80.13deg, #45494E 18.19%, #6E757D 92.37%)'
        asideBackground='transparent'
        footerBackground='transparent'
      >
        <SEO
          description={lang === 'en' ? titleEn : titleRu}
          ogDescription={lang === 'en' ? titleEn : titleRu}
          keywords='Бренд, Петруха, продукция'
          ogTitle={lang === 'en' ? titleEn : titleRu}
          title={lang === 'en' ? titleEn : titleRu}
        />
        <PetruhaBrandContent contentBackground='transparent'/>
      </BasePageLayout>
    </div>
  );
};

export default PetruhaPage;
