import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {Pagination, Autoplay} from 'swiper';
import './news.scss';
import { Link } from 'react-router-dom';

SwiperCore.use([Pagination, Autoplay]);

const NewsSlider = ({articles, mobile}) => (
  <div className="swiperContainer3">
    <Swiper
      pagination={{ clickable: true }}
      id="newsSwiper"
      className="newsSwiper"
      setWrapperSize="true"
      loop="true"
      onAfterInit={(swiper) => swiper.update()}
    >
      {
        articles.map((item) => (
          <SwiperSlide key={`article-${item.id}`}>
            <Link to={`/promotions/${item.slug}`}>
              <div className="mainWrapper_col_third_news_slider" style={{backgroundImage: `url(${mobile && item.mobile_preview_image ? item.mobile_preview_image : item.mainpage_preview_image})`}}>
                <div className="header">{item.title}</div>
              </div>
            </Link>
          </SwiperSlide>
        ))
      }
    </Swiper>
  </div>
);

export default NewsSlider;
