import React from 'react';
import './preloader.scss';
import {connect} from 'react-redux';

class Preloader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      preloadingClass: 'preloadedImage',
      preloadingAnimatedClass: 'preloadedImage animatedZoom',
    };
  }

  render() {
    const {startZoom} = this.props;
    const {preloadingAnimatedClass, preloadingClass} = this.state;
    return ( 
      <div className='preloader' >
        <div className={startZoom ? preloadingAnimatedClass : preloadingClass}/>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    variablesLoadingFromAPI: state.VariablesReducer.variablesLoadingFromAPI,
  }),
)(Preloader);