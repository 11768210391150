import React, {useEffect} from 'react';
import BasePageLayout from '../../components/layouts/BasePageLayout';
import ContactsPageContent from '../../components/ContactsPageContent';
import SEO from '../../components/SEO';

const ContactsPage = (
  {
    location: {
      hash,
    },
    isMobile,
    lang,
  },
) => {
  const titleRu = 'Актуальные вакансии компании Петруха — работа на производстве';
  const titleEn = 'Current vacancies of the Petruha company - work in production';

  useEffect(() => {
    document.title = lang === 'en' ? titleEn : titleRu;
  });
  return (
    <BasePageLayout mobileMenuColor={isMobile ? 'transparent' : 'white'} >
      <SEO
        description={lang === 'en' ? titleEn : titleRu}
        ogDescription={lang === 'en' ? titleEn : titleRu}
        keywords='Петруха, контакты, вакансии'
        ogTitle={lang === 'en' ? titleEn : titleRu}
        title={lang === 'en' ? titleEn : titleRu}
      />
      <ContactsPageContent hash={hash}/>
    </BasePageLayout>
  );
};

export default ContactsPage;
