import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import './petruhaMKIPageContent.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Mousewheel, Pagination, Keyboard,
} from 'swiper/core';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { VideoPopupActions } from '../../redux/actions';

SwiperCore.use([Mousewheel, Pagination, Keyboard]);

function PetruhaMKIPageContent(
  {
    contentBackground,
    openPopup,
    content: {
      petruhaMKI: {
        info = {},
      } = {},
      brandsMenu,
      buttons,
    } = {},
    isMobile,
  },
) {
  const dailyMKI = info.benefits ? info.benefits.map((item) => (
    <SwiperSlide key={`petruha-mki-products-${item.id}`}>
      <div
        className="mki-swiper-content"
      >
        <div className="icon" style={{backgroundImage: `url(${item.image})`}}/>
        <div className="text">
          {item.text}
        </div>
      </div>
    </SwiperSlide>
  )) : null;

  const swiperElement = (
    <Swiper
      className="petruhaMKIPageSwiper"
      id="petruhaMKIPageSwiper"
      loop="true"
      setWrapperSize="true"
      pagination={{ clickable: true }}
      onAfterInit={(swiper) => {
        swiper.update();
      }}
      slidesPerView="1"
      keyboard={{
        enabled: true,
        onlyInViewport: true,
      }}
    >
      { dailyMKI }
    </Swiper>
  );

  const petruhaProductImages = info.gallery ? info.gallery.map((item) => (
    <SwiperSlide key={`petruha-mki-products-${item.id}`}>
      <div
        className="cover"
        style={{backgroundImage: `url(${item.image})`}}
      />
    </SwiperSlide>
  )) : null;

  const swiperElementProduct = (
    <Swiper
      className="petruhaMKIPageSwiper"
      id="petruhaMKISwiperProduct"
      loop="true"
      setWrapperSize="true"
      pagination={{ clickable: true }}
      onAfterInit={(swiper) => {
        swiper.update();
      }}
      slidesPerView="1"
      keyboard={{
        enabled: true,
        onlyInViewport: true,
      }}
    >
      { petruhaProductImages }
    </Swiper>
  );
  // Из-за слайдеров страница на мобилке открывается посредине.
  window.scrollTo(0, 0);
  const [setOpacity] = useState(0);

  return (
    <div onScroll={(e) => { setOpacity(e.target.scrollTop * 0.03); }} className={`petruhaMKIWrapper petruhaMKIWrapper_${contentBackground}`}>
      {
        isMobile && (
          <div className='logo-wrapper'>
            <Link
              className="logo"
              to='/'
            />
            <nav className='brandsNavigation'>
              <Link to='/petruha-brand' className="brands-nav-item">{brandsMenu.brands_petruha}</Link>
              {/* <Link to='/petruha-master' className="brands-nav-item">{brandsMenu.brands_petruha_master}</Link> */}
              {/* <Link to='/petruha-gotovo' className="brands-nav-item">{brandsMenu.brands_petruha_done}</Link> */}
              <Link to='/petruha-kolbasnye-izdeliya' className="brands-nav-item">{brandsMenu.brands_sausages}</Link>
            </nav>
            <h1 className="petruhaMKIWrapper_header">Всегда {/* info.title */}
              <span>
                <br/>
                кстати! {/* info.subtitle */}
              </span>
            </h1>
          </div>
        )
      }
      {
        !isMobile && (
          <>
            <Link
              className="logo"
              to='/petruha-kolbasnye-izdeliya/products'
              // style={{ opacity: `${1 - opacity}` }}
            />
            <nav className='brandsNavigation'>
              <Link to='/petruha-brand' className="brands-nav-item">{brandsMenu.brands_petruha}</Link>
              <Link to='/petruha-master' className="brands-nav-item">{brandsMenu.brands_petruha_master}</Link>
              <Link to='/petruha-gotovo' className="brands-nav-item">{brandsMenu.brands_petruha_done}</Link>
              <Link to='/petruha-kolbasnye-izdeliya' className="brands-nav-item">{brandsMenu.brands_sausages}</Link>
            </nav>
            <h1 className="petruhaMKIWrapper_header">
              {/* info.title */}
              <span>{info.subtitle}</span>
            </h1>
          </>
        )
      }
      <div className="petruhaMKIWrapper_content">
        <div
          className="first-col"
          style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.6) 100%), url(${info.image})`}}
          onClick={() => {
            if (info.video) {
              openPopup(info.video);
            }
          }}
        >
          <div className="play-wrapper">
            {
              info.video ? (
                <div className="play-icon"/>
              ) : null
            }
            <div className="play-description">{info.video_text}</div>
          </div>
        </div>
        <div className="second-col">
          <div className="top">
            <div className="top-left">
              <div className="descr">
                {ReactHtmlParser(info.text)}
              </div>
              { info.benefits.length > 0 ? (
                <div className="mki-swiper-container">
                  {swiperElement}
                </div>
              ) : null}
            </div>
            <div className="image top-right">
              {swiperElementProduct}
            </div>
          </div>
          <Link to='/petruha-kolbasnye-izdeliya/products' className="bottom">
            <div className="flag">{buttons.button_whole_assortment}</div>
            <div className="cover" style={{backgroundImage: `url(${info.packing_image})`}}/>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    isMobile: state.ResizeReducer.isMobile,
    content: state.ContentReducer.content,
  }),
  (dispatch) => ({
    openPopup: (url) => {
      dispatch(VideoPopupActions.openVideoPopup(url));
    },
  }),
)(PetruhaMKIPageContent);
