import React from 'react';
import {connect} from 'react-redux';

import {
  ResizeActions,
  VariablesActions,
  ContentActions,
} from './js/redux/actions';

import Main from './js/Main';
import Preloader from './js/components/Preloader';
import VideoPopup from './js/components/VideoPopup';

const durationOpenSite = 2000;

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showWebsite: false,
      startZoom: false,
      needRedirect: window.location.hostname === process.env.REACT_APP_DOMAIN_COM,
    };
  }

  componentDidMount() {
    const {loadVariablesFromApi, getContent} = this.props;

    // const { needRedirect } = this.state;
    const needRedirect = false;
    loadVariablesFromApi().then(() => {
      const {variables: {variables: {country_code: countryCode = null} = {}}} = this.props;

      if (needRedirect && countryCode) {
        if (countryCode === 'RU') {
          window.location.href = `https://${process.env.REACT_APP_DOMAIN_RU}${window.location.pathname}${window.location.search}`;
        } else {
          window.location.href = `https://${process.env.REACT_APP_DOMAIN_BY}${window.location.pathname}${window.location.search}`;
        }
      } else {
        this.setState({
          needRedirect: false,
        });
      }

      this.updateDimensions();

      window.addEventListener('resize', this.updateDimensions);
      setTimeout(() => {
        getContent();
      }, 200);
    });
  }

  componentDidUpdate(prev) {
    const {contentLoadingFromAPI} = this.props;

    if (contentLoadingFromAPI && contentLoadingFromAPI !== prev.contentLoadingFromAPI) {
      this.startAnimate();
      setTimeout(() => {
        this.showSite();
      }, 100);
    }
  }

  startAnimate = () => {
    this.setState({
      startZoom: true,
    });
  };

  showSite = () => {
    setTimeout(() => {
      this.setState({
        showWebsite: true,
      });
    }, durationOpenSite);
  };

  updateDimensions = () => {
    const {resize} = this.props;

    const {clientWidth} = document.body;
    const {innerHeight} = window;

    resize(clientWidth, innerHeight);
  };

  render() {
    const {
      showWebsite,
      startZoom,
      needRedirect,
    } = this.state;

    return !needRedirect ? (
      <div className='Site'>
        {
          (showWebsite)
            ? (
              <>
                <Main/>
              </>
            )
            : (
              <div className='preload'>
                <Preloader startZoom={startZoom}/>
              </div>
            )
        }
        <VideoPopup/>
      </div>
    ) : null;
  }
}

export default connect(
  (state) => ({
    contentLoadingFromAPI: state.ContentReducer.contentLoadingFromAPI,

    variables: state.VariablesReducer.variables,
  }),
  (dispatch) => ({
    loadVariablesFromApi: () => VariablesActions.loadVariablesFromApi()(dispatch),
    resize: (width, height) => {
      dispatch(ResizeActions.resize(width, height));
    },
    getContent: () => {
      dispatch(ContentActions.getContent());
    },
  }),
)(App);
