import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import SwiperCore, {Pagination, Navigation, Autoplay} from 'swiper';
import Tada from 'react-reveal/Tada';
import OnlineSlider from './Online';
import MealForBrainSlider from './MealForBrain';
import NewsSlider from './News';
import Master from './Master';
import Ready from './Ready';
import Sausages from './Sausages';

import './mainPageContent.scss';
import {
  FirstLoadActions,
} from '../../redux/actions';

SwiperCore.use([Pagination, Navigation, Autoplay]);

class MainPageContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      color: 'white',
    };
  }

  render() {
    const {
      firstLoad,
      content: {
        petruhaOnline: {
          productionStages = [],
          mainPageImage,
        } = {},
        petruhaDaily: {
          cardsOnMainPage = [],
        } = {},
        articles,
        mainMenu,
        others,
      } = {},
      isMobile,
    } = this.props;

    const stages = productionStages.filter((item) => item.show_on_main_page);
    const daily = cardsOnMainPage.slice(0, 3);
    const showingArticles = articles.slice(0, 3);

    const petruhaOnlineImg = mainPageImage?.image;

    const {color} = this.state;

    const domain = window.location.hostname;

    return (
      <>
        <div className={`mainWrapper mainWrapper_${color}`}>
          {!isMobile ? (
            <>
              <div className="mainTitle">
                <h1>Петруха — официальный сайт производителя продукции из мяса кур различных торговых марок</h1>
              </div>
              <div className="mainWrapper_col mainWrapper_col_first">
                <div className="mainWrapper_col_first_menu">
                  <ul>
                    <li className="menu_item">
                      <Link to='/petruha-brand'>{mainMenu.menu_brands}</Link>
                    </li>
                    <li className="menu_item">
                      <Link to='/recipes'>{mainMenu.menu_recipes}</Link>
                    </li>
                    <li className="menu_item">
                      <Link to='/petruha-online/'>{mainMenu.menu_petruha_online}</Link>
                    </li>
                    <li className="menu_item">
                      <Link to="/petruha-daily/">{mainMenu.menu_food_for_thought}</Link>
                    </li>
                    <li className="menu_item">
                      <Link to='/promotions/'>{mainMenu.menu_news_and_promotions}</Link>
                    </li>
                    <li className="menu_item">
                      <a href='https://t.me/petruhaonline' target='_blank' rel="noreferrer">Петруха Online</a>
                    </li>
                    {
                      domain !== process.env.REACT_APP_DOMAIN_BY ? (
                        <li className="menu_item">
                          <a href="https://petruhastore.ru" target="_blank" rel="noreferrer noopener" className='store-item'>{mainMenu.menu_online_store}</a>
                        </li>
                      ) : null
                    }
                    <li className="menu_item">
                      <Link className='contacts-item' to='/contacts'>{mainMenu.menu_contacts}</Link>
                    </li>
                  </ul>
                  {firstLoad
                  && (
                    <>
                      <div className="mainWrapper_col_first_menu_line_horizontal_top"/>
                      <div className="mainWrapper_col_first_menu_line_horizontal_bottom"/>
                      <div className="mainWrapper_col_first_menu_animated_fill-from-top"/>
                    </>
                  )}
                </div>
                <a href="https://t.me/petruhaonline" rel="noreferrer" target="_blank">
                  <div className="mainWrapper_col_first_img">
                    <Tada forever duration={3700} delay={4000}>
                      <div className='chicken'/>
                    </Tada>
                    <div className="marker">
                      <p>{mainMenu.menu_bonus}</p>
                    </div>
                    {firstLoad
                    && (
                      <>
                        <div className="mainWrapper_col_first_img_animated_fill-from-top2"/>
                        <div className="mainWrapper_col_first_right_vertical_line"/>
                        <div className="mainWrapper_col_first_left_vertical_line"/>
                      </>
                    )}
                  </div>
                </a>
              </div>
              <div className="mainWrapper_col mainWrapper_col_second">
                <div className="mainWrapper_col_second_online">
                  <Link className='links' to='/petruha-online'>
                    <div className="marker">
                      <p>{mainMenu.menu_petruha_online}</p>
                    </div>
                    <OnlineSlider productionStages={stages} title={others.how_do_we_do_it}/>
                  </Link>
                  <div
                    className="mainWrapper_col_second_online_animation"
                    style={{backgroundImage: `url(${petruhaOnlineImg})`}}
                  >
                    <Link to='/petruha-online/' className="land-play-icon"/>
                  </div>
                  {firstLoad
                  && (
                    <>
                      <div className="mainWrapper_col_second_online_line_horizontal_top"/>
                      <div className="mainWrapper_col_second_online_line_horizontal_bottom"/>
                      <div className="mainWrapper_col_second_online_animated_fill-from-bottom"/>
                    </>
                  )}
                </div>
                <Link className='links' to='/petruha-daily'>
                  <div className="mainWrapper_col_second_meal-for-brain">
                    <div className="marker">
                      <p>{mainMenu.menu_food_for_thought}</p>
                    </div>
                    <MealForBrainSlider items={daily}/>
                    {firstLoad
                    && (
                      <>
                        <div className="mainWrapper_col_second_meal-for-brain_animated_fill-from-bottom2"/>
                      </>
                    )}
                  </div>
                </Link>
              </div>
              <div className="mainWrapper_col mainWrapper_col_third">
                <Link to='/recipes' className="mainWrapper_col_third_recipes">
                  <div className="marker">
                    <p>{mainMenu.menu_recipes}</p>
                  </div>
                  {firstLoad
                  && (
                    <>
                      <div className="mainWrapper_col_third_recipes_line_horizontal_top"/>
                      <div className="mainWrapper_col_third_recipes_line_horizontal_bottom"/>
                      <div className="mainWrapper_col_third_recipes_animated_fill-from-top"/>
                    </>
                  )}
                </Link>
                <div className="mainWrapper_col_third_news">
                  <div className="marker">
                    <a href="/promotions/">{mainMenu.menu_news_and_promotions}</a>
                  </div>
                  <NewsSlider articles={showingArticles}/>
                  {firstLoad
                  && (
                    <>
                      <div className="mainWrapper_col_third_news_animated_fill-from-top"/>
                    </>
                  )}
                </div>
                {firstLoad
                && (
                  <>
                    <div className="mainWrapper_col_third_right_vertical_line"/>
                    <div className="mainWrapper_col_third_left_vertical_line"/>
                  </>
                )}
              </div>
              <div className="mainWrapper_col mainWrapper_col_fourth">
                <Link to='/petruha-brand' className="mainWrapper_col_fourth_big-brand">
                  <div className="marker">
                    <p>{mainMenu.menu_brands}</p>
                  </div>
                  {firstLoad
                  && (
                    <>
                      <div className="mainWrapper_col_fourth_big-brand_line_horizontal_top"/>
                      <div className="mainWrapper_col_fourth_big-brand_line_horizontal_bottom"/>
                      <div className="mainWrapper_col_fourth_big-brand_animated_fill-from-bottom"/>
                    </>
                  )}
                </Link>
                <div className="mainWrapper_col_fourth_brand-range">
                  <div className="mainWrapper_col_fourth_brand-range_slider">
                    <Link to='/petruha-master' className="slider-item master-slider-main">
                      <Master/>
                      <div className="master_cover"/>
                    </Link>
                    <Link to='/petruha-gotovo' className="slider-item">
                      <Ready/>
                      <div className="ready-cover"/>
                    </Link>
                    <Link to='/petruha-kolbasnye-izdeliya' className="slider-item">
                      <Sausages/>
                      <div className="sausages-cover"/>
                    </Link>
                  </div>
                  {firstLoad
                  && (
                    <>
                      <div className="mainWrapper_col_fourth_brand-range_animated_fill-from-bottom2"/>
                    </>
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="mainWrapper_col mainWrapper_col_second">
                <Link to='/petruha-online'>
                  <div className="mainWrapper_col_second_online">
                    <div className="marker">
                      <p>{mainMenu.menu_petruha_online}</p>
                    </div>
                    <OnlineSlider productionStages={stages} title={others.how_do_we_do_it}/>
                    <div
                      className="mainWrapper_col_second_online_animation"
                      style={{backgroundImage: `url(${petruhaOnlineImg})`}}
                    >
                      <Link to='/petruha-online/' className="land-play-icon"/>
                    </div>
                    {firstLoad
                    && (
                      <>
                        <div className="mainWrapper_col_second_online_line_horizontal_top"/>
                        <div className="mainWrapper_col_second_online_line_horizontal_bottom"/>
                        <div className="mainWrapper_col_second_online_animated_fill-from-bottom"/>
                      </>
                    )}
                  </div>
                </Link>
                <Link to='/petruha-daily'>
                  <div className="mainWrapper_col_second_meal-for-brain">
                    <div className="marker">
                      <p>{mainMenu.menu_food_for_thought}</p>
                    </div>
                    <MealForBrainSlider items={daily}/>
                    {firstLoad
                    && (
                      <>
                        <div className="mainWrapper_col_second_meal-for-brain_animated_fill-from-bottom2"/>
                      </>
                    )}
                  </div>
                </Link>
              </div>
              <div className="mainWrapper_col mainWrapper_col_third">
                <Link to='/recipes' className="mainWrapper_col_third_recipes">
                  <div className="marker">
                    <p>{mainMenu.menu_recipes}</p>
                  </div>
                  {firstLoad
                  && (
                    <>
                      <div className="mainWrapper_col_third_recipes_line_horizontal_top"/>
                      <div className="mainWrapper_col_third_recipes_line_horizontal_bottom"/>
                      <div className="mainWrapper_col_third_recipes_animated_fill-from-top"/>
                    </>
                  )}
                </Link>
                <Link to='/promotions' className="mainWrapper_col_third_news">
                  <div className="marker">
                    <a href="/promotions/">{mainMenu.menu_news_and_promotions}</a>
                  </div>
                  <NewsSlider mobile articles={showingArticles}/>
                  {firstLoad
                  && (
                    <>
                      <div className="mainWrapper_col_third_news_animated_fill-from-top"/>
                    </>
                  )}
                </Link>
                {firstLoad
                && (
                  <>
                    <div className="mainWrapper_col_third_right_vertical_line"/>
                    <div className="mainWrapper_col_third_left_vertical_line"/>
                  </>
                )}
                <a href="https://t.me/petruhaonline" rel="noreferrer" target="_blank">
                  <div className="mainWrapper_col_first_img">
                    <Tada forever duration={3700} delay={4000}>
                      <div className='chicken'/>
                    </Tada>
                    <div className="marker">
                      <p>{mainMenu.menu_bonus}</p>
                    </div>
                    {firstLoad
                    && (
                      <>
                        <div className="mainWrapper_col_first_img_animated_fill-from-top2"/>
                        <div className="mainWrapper_col_first_right_vertical_line"/>
                        <div className="mainWrapper_col_first_left_vertical_line"/>
                      </>
                    )}
                  </div>
                </a>
              </div>
            </>
          )}
        </div>
        {isMobile && (
          <div className="mainWrapper_col mainWrapper_col_fourth">
            <Link to='/petruha-brand' className="mainWrapper_col_fourth_big-brand">
              {/* <div className="marker">
                <p>{mainMenu.menu_brands}</p>
              </div> */}
              {firstLoad
              && (
                <>
                  <div className="mainWrapper_col_fourth_big-brand_line_horizontal_top"/>
                  <div className="mainWrapper_col_fourth_big-brand_line_horizontal_bottom"/>
                  <div className="mainWrapper_col_fourth_big-brand_animated_fill-from-bottom"/>
                </>
              )}
            </Link>
            <div className="mainWrapper_col_fourth_brand-range">
              <div className="mainWrapper_col_fourth_brand-range_slider">
                <Link to='/petruha-master' className="slider-item master-slider-main">
                  <Master/>
                  <div className="master_cover"/>
                </Link>
                <Link to='petruha-gotovo' className="slider-item">
                  <Ready/>
                  <div className="ready-cover"/>
                </Link>
                <Link to='/petruha-kolbasnye-izdeliya' className="slider-item">
                  <Sausages/>
                  <div className="sausages-cover"/>
                </Link>
              </div>
              {firstLoad
              && (
                <>
                  <div className="mainWrapper_col_fourth_brand-range_animated_fill-from-bottom2"/>
                </>
              )}
            </div>
          </div>
        )}
      </>
    );
  }
}

export default connect(
  (state) => ({
    isMobile: state.ResizeReducer.isMobile,
    firstLoad: state.FirstLoadReducer.firstLoad,
    content: state.ContentReducer.content,
  }),
  (dispatch) => ({
    loadFirst: (firstLoad) => {
      dispatch(FirstLoadActions.loadFirst(firstLoad));
    },
  }),
)(MainPageContent);
