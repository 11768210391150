import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {Autoplay} from 'swiper';
import './ready.scss';

SwiperCore.use([Autoplay]);

const Ready = () => (
  <div to='/petruha-gotovo' className="swiperContainer4">
    <Swiper
      id="mealForBrainSwiper"
      slidesPerView="1"
      className="mealForBrainSwiper"
      loop="true"
      speed={2000}
      onAfterInit={(swiper) => swiper.update()}
      autoplay={{
        delay: '0',
      }}
    >
      <SwiperSlide>
        <div className="mainWrapper_col_4_ready_slider">
          <div className="slide-1 slide"/>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="mainWrapper_col_4_ready_slider">
          <div className="slide-2 slide"/>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="mainWrapper_col_4_ready_slider">
          <div className="slide-3 slide"/>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="mainWrapper_col_4_ready_slider">
          <div className="slide-4 slide"/>
        </div>
      </SwiperSlide>
    </Swiper>
  </div>
);

export default Ready;
