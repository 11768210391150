import { ActionsConstants } from '../../../constants';

const closeVideoPopup = () => (dispatch) => {
  dispatch({
    type: ActionsConstants.VideoPopup.CLOSE_VIDEO_POPUP,
    payload: {
      isOpen: false,
      videoUrl: null,
    },
  });
};

const openVideoPopup = (videoUrl = '', isOpen = true) => (dispatch) => {
  dispatch({
    type: ActionsConstants.VideoPopup.OPEN_VIDEO_POPUP,
    payload: {
      isOpen,
      videoUrl,
    },
  });
};

export default {
  closeVideoPopup,
  openVideoPopup,
};
