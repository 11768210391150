import React from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';

import './promotionsContent.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Keyboard, Pagination,
} from 'swiper/core';

import History from '../../utils/History';

import Select from '../UI/Select';
import {ContentActions} from '../../redux/actions';

SwiperCore.use([Keyboard, Pagination]);

class PromotionsContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newsAndEvents: [],
      promotions: [],
      currentSlide: null,
      currentType: 'Новость',
    };
  }

  componentDidMount() {
    // Из-за слайдеров страница на мобилке открывается посредине.
    window.scrollTo(0, 0);

    const {getNews} = this.props;
    this.setState({
      // country_code: variables.country_code,
    });
    getNews();
  }

  componentDidUpdate(prevProps) {
    const { news } = this.props;
    // const countryCode = variables.country_code;
    if (prevProps.news !== news) {
      // switch (countryCode) {
      //   case 'BY':
      //     this.updateNews(news.filter((item) => item.is_by));
      //     this.setState({
      //       allNews: false,
      //     });
      //     break;
      //   case 'RU':
      //     this.updateNews(news.filter((item) => item.is_ru));
      //     this.setState({
      //       allNews: false,
      //     });
      //     break;
      //   default:
      //     this.updateNews(news);
      //     this.setState({
      //       allNews: true,
      //     });
      // }
      // this.updateNews(news);
      this.setFilterCountry();
    }
  }

  updateNews = (articles) => {
    const newsAndEvents = articles.filter((item) => item.type === 'Новость' || item.type === 'Мероприятие');
    // const promotions = articles.filter((item) => item.type === 'Акция');
    const news = articles.filter((item) => item.type === 'Новость');

    this.setState({
      newsAndEvents,
      // promotions,
      currentSlide: news.length ? news[0] : null,
    });
  }

  cleanFilterCountry = () => {
    const { news } = this.props;
    this.updateNews(news);
    this.setState({
      allNews: true,
    });
  }

  setFilterCountry = () => {
    const { news, variables } = this.props;
    const countryCode = variables.country_code;
    switch (countryCode) {
      case 'BY':
        this.updateNews(news.filter((item) => item.is_by));
        this.setState({
          allNews: false,
        });
        break;
      case 'RU':
        this.updateNews(news.filter((item) => item.is_ru));
        this.setState({
          allNews: false,
        });
        break;
      default:
        this.updateNews(news);
        this.setState({
          allNews: true,
        });
    }
  }

  changeSlide = (newsId) => {
    const { newsAndEvents } = this.state;
    this.setState({
      currentSlide: newsAndEvents.find((item) => item.id === newsId),
    });
  }

  changeType = (option) => {
    const {newsAndEvents} = this.state;
    const newsOrEvent = newsAndEvents.filter((item) => item.type === option.value);

    this.setState({
      currentType: option.value,
      currentSlide: newsOrEvent.length ? newsOrEvent[0] : null,
    });
  }

  render() {
    const {
      newsAndEvents,
      promotions,
      currentSlide,
      currentType,
    } = this.state;

    const {
      content: {
        buttons,
        titles,
        others,
      } = {},
      isMobile,
    } = this.props;

    const promoSlides = promotions.map((item) => (
      <SwiperSlide key={`promotions-${item.id}`}>
        <div className="promotions-slide-wrapper">
          <div className="icon" style={{backgroundImage: `url(${item.preview_image})`}}/>
          <div className="content">
            <div className="header">{item.title}</div>
            {
              item.brand && (
                <div className="logo" style={{backgroundImage: `url(${item.brand.logo})`}}/>
              )
            }
            <div className="date">{item.date}</div>
            <div className="text">{item.preview_text}</div>
            <Link to={`/promotions/${item.slug}`} className="more">{buttons.button_more}</Link>
          </div>
        </div>
      </SwiperSlide>
    ));

    const swiperElement = (
      <Swiper
        className="promoSwiper"
        id="promoSwiper"
        loop='true'
        freemode="false"
        setWrapperSize='true'
        // centeredSlides='true'
        centeredSlidesBounds='true'
        onAfterInit={(swiper) => {
          swiper.update();
          // swiper.mousewheel.enable();
        }}
        slidesPerView='1.4'
        keyboard={{
          enabled: true,
          onlyInViewport: true,
        }}
        pagination={{
          el: '.swiper-pagination',
          type: 'bullets',
          hideOnClick: 'false',
        }}
        breakpoints={{
          1920: {
            slidesPerView: 1.4,
          },
          1850: {
            slidesPerView: 1.3,
          },
          1280: {
            slidesPerView: 1.18,
            // pagination: true,
          },
          800: {
            slidesPerView: 1,
          },
          600: {
            slidesPerView: 1,
          },
          480: {
            slidesPerView: 1,
            spaceBetween: 50,
          },
          428: {
            slidesPerView: 1,
            spaceBetween: 50,
          },
          390: {
            slidesPerView: 1,
            spaceBetween: 50,
          },
          200: {
            slidesPerView: 1,
            spaceBetween: 20,
            freeMode: false,
          },
        }}
      >
        { promoSlides }
        <div className='swiper-pagination'/>
      </Swiper>
    );

    const newsSlides = newsAndEvents.filter((item) => item.type === currentType).map((item) => (
      <SwiperSlide key={`news-${item.id}`}>
        <div
          className={item === currentSlide ? 'news-slide-wrapper current-slide' : 'news-slide-wrapper'}
          onClick={
            window.innerWidth < 1200 ? (
              () => History.getInstance().history.push(`/promotions/${item.slug}`)
            ) : (
              () => this.changeSlide(item.id)
            )
          }
        >
          <div className="icon" style={{backgroundImage: `url(${item.mini_preview_image})`}}/>
          <div className="news-slide-header">{item.title}</div>
          <div className="date">{item.date}</div>
          <div className="text">{item.preview_text}</div>
        </div>
      </SwiperSlide>
    ));

    const swiperElement2 = (
      <Swiper
        className="newsSwiper"
        id="newsSwiper"
        initialSlide="0"
        runCallbacksOnInit="false"
        setWrapperSize="true"
        slidesPerView="4.5"
        onAfterInit={(swiper) => {
          swiper.update();
          // swiper.mousewheel.enable();
        }}
        grabCursor="true"
        keyboard={{
          enabled: true,
          onlyInViewport: true,
        }}
        pagination={{
          type: 'bullets',
          hideOnClick: 'false',
          clickable: true,
          dynamicBullets: true,
        }}
        breakpoints={{
          1800: {
            slidesPerView: 4.5,
          },
          1500: {
            slidesPerView: 4,
          },
          1280: {
            slidesPerView: 3,
          },
          980: {
            slidesPerView: 2.5,
          },
          767: {
            slidesPerView: 2,
          },
          200: {
            slidesPerView: 1,
          },
        }}
      >
        { newsSlides }
      </Swiper>
    );

    const allOptions = [
      {id: 11, value: 'empty', label: ''},
      {id: 1, value: 'Новость', label: others.news},
      {id: 2, value: 'Мероприятие', label: others.events},
    ];

    const { allNews } = this.state;

    const options = allOptions.filter((option) => option.value !== currentType);
    const newsOfEvents = currentType === 'Новость' ? titles.title_news : titles.title_events;

    return (
      <div className='promoWrapper'>
        { promotions.length ? (
          <div className="promo">
            <Link to='/' className="petruhaOnlineWrapper_logo"/>
            <h1 className="promo-header">{titles.title_promotions}</h1>
            <div className="promo-swiper-wrapper">
              {swiperElement}
            </div>
          </div>
        ) : null }
        <div className="news">
          { !isMobile && (
            <a className="logo" href="/">
              <div/>
            </a>
          )}
          {/* <div className="news-select">
            <Select
              options={options}
              id='newsOrEvents'
              showItems="true"
              toggled
              customInputClass="recipes-select"
              defaultSelectOptionId={11}
              placeholder={newsOfEvents}
              onSelectItem={(item) => this.changeType(item)}
              textInputAllowed={false}
            />
          </div> */}

          <div className="header">{newsOfEvents}</div>
          { !allNews ? (
            <div className="news-region">Данные новости показаны для вашего региона
              <span className="news-region-all" onClick={this.cleanFilterCountry}>Показать все</span>
            </div>
          ) : (
            // <div className="news-region-null" />
            <div className="news-region">
              <span className="news-region-all" onClick={this.setFilterCountry}>Вернуться</span>
            </div>
          ) }
          <div className="news-select-bottom">
            <Select
              options={options}
              id='newsOrEvents'
              showItems="true"
              toggled
              customInputClass="recipes-select"
              defaultSelectOptionId={11}
              placeholder={newsOfEvents}
              onSelectItem={(item) => this.changeType(item)}
              textInputAllowed={false}
            />
          </div>
          <div className="content">
            <div className="top">
              {
                currentSlide && (
                  <>
                    <div className="text">
                      <div className="header">{currentSlide.title}</div>
                      <div className="article-content">
                        <div className="date">{currentSlide.date}</div>
                        <div className="text-content">{currentSlide.preview_text}</div>
                        <Link to={`/promotions/${currentSlide.slug}`} className="more">{buttons.button_more}</Link>
                      </div>
                    </div>
                    <div className="image" style={{backgroundImage: `url(${currentSlide.preview_image})`}}/>
                  </>
                )
              }
            </div>
            <div className="bottom">
              {swiperElement2}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default connect(
  (state) => ({
    content: state.ContentReducer.content,
    news: state.ContentReducer.news,
    isMobile: state.ResizeReducer.isMobile,
    variables: state.VariablesReducer.variables.variables,
  }),
  (dispatch) => ({
    getNews: () => ContentActions.getNews()(dispatch),
  }),
)(PromotionsContent);
