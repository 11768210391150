import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import {HelmetProvider} from 'react-helmet-async';
// import SnowStorm from 'react-snowstorm';
import App from './App';
import './assets/css/reset.css';
import './assets/fonts/stylesheet.css';
import './assets/css/swiper-bundle.css';

import ReduxStoreService from './js/services/ReduxStoreService';
import TranslateProvider from './js/services/language/TranslateProvider';
import History from './js/utils/History';

document.title = 'Петруха';
const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(
    <Provider store={ReduxStoreService.getInstance().store}>
      <TranslateProvider>
        <HelmetProvider>
          <Router history={History.getInstance().history}>
            {/* <SnowStorm /> */}
            <App/>
          </Router>
        </HelmetProvider>
      </TranslateProvider>
    </Provider>,
    rootElement,
  );
} else {
  ReactDOM.render(
    <Provider store={ReduxStoreService.getInstance().store}>
      <TranslateProvider>
        <HelmetProvider>
          <Router history={History.getInstance().history}>
            {/* <SnowStorm /> */}
            <App/>
          </Router>
        </HelmetProvider>
      </TranslateProvider>
    </Provider>,
    rootElement,
  );
}
