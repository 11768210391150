import Auth from './auth';
import Variables from './variables';
import Resize from './resize';
import Popup from './popup';
import Recipes from './recipes';
import FirstLoad from './firstload';
import About from './about';
import Content from './content';
import VideoPopup from './videopopup';
import Search from './search';
import Chat from './chat';

export default {
  Auth,
  Variables,
  Resize,
  Popup,
  Recipes,
  FirstLoad,
  About,
  Content,
  VideoPopup,
  Search,
  Chat,
};
