import React from 'react';
import { connect } from 'react-redux';

import TranslateContext from './TranslateContext';

class TranslateProvider extends React.Component {
  constructor(props) {
    super(props);

    const { languageSelected: { language } } = props;

    TranslateContext.init(language);
  }

  render() {
    const {
      children,
      languageSelected: { language } = {},
    } = this.props;

    const Context = TranslateContext.get();

    return (
      <Context.Provider value={{ language }}>
        {children}
      </Context.Provider>
    );
  }
}

export default connect(
  (state) => ({
    languageSelected: state.VariablesReducer.variables,
  }),
)(TranslateProvider);
