import React, {useEffect} from 'react';
import MainPageContent from '../../components/MainPageContent';
import BasePageLayout from '../../components/layouts/BasePageLayout';
import SEO from '../../components/SEO';

const MainPage = (props) => {
  const titleRu = 'Петруха — официальный сайт производителя продукции из мяса птицы';
  const titleEn = 'Petruha - the official website of the manufacturer of products from chicken meat of various brands';

  useEffect(() => {
    document.title = props.lang === 'en' ? titleEn : titleRu;
  });
  const { isMobile } = props;
  return (
    <BasePageLayout hideBurgerMenu={!isMobile && 'true'}>
      <SEO
        title={props.lang === 'en' ? titleEn : titleRu}
        ogTitle={props.lang === 'en' ? titleEn : titleRu}
      />
      <MainPageContent/>
    </BasePageLayout>
  );
};

export default MainPage;
