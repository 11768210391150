import React from 'react';
import { Link } from 'react-router-dom';
import './petruhaBrandContent.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Mousewheel, Pagination, Keyboard,
} from 'swiper/core';
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';
import { VideoPopupActions } from '../../redux/actions';

SwiperCore.use([Mousewheel, Pagination, Keyboard]);

function PetruhaBrandContent(
  {
    contentBackground,
    openPopup,
    content: {
      petruhaBrand: {
        info = {},
      } = {},
      brandsMenu,
      buttons,
    } = {},
    isMobile,
  },
) {
  const dailyPetruha = info.gallery ? info.gallery.map((item) => (
    <SwiperSlide key={`petruha-brand-products-${item.id}`}>
      <div
        className="cover"
        style={{ backgroundImage: `url(${item.image})` }}
      />
    </SwiperSlide>
  )) : null;

  const swiperElement = (
    <Swiper
      className="petruhaBrandSwiper"
      id="petruhaBrandSwiper"
      loop="true"
      setWrapperSize="true"
      pagination={{ clickable: true }}
      onAfterInit={(swiper) => {
        swiper.update();
      }}
      slidesPerView="1"
      keyboard={{
        enabled: true,
        onlyInViewport: true,
      }}
    >
      {dailyPetruha}
    </Swiper>
  );
  // Из-за слайдеров страница на мобилке открывается посредине.
  window.scrollTo(0, 0);
  return (
    <div className={`petruhaBrandWrapper petruhaBrandWrapper_${contentBackground}`}>
      <Link
        className="logo"
        to='/petruha-brand/products'
      />
      <nav className='brandsNavigation'>
        <Link to='/petruha-brand' className="brands-nav-item">{brandsMenu.brands_petruha}</Link>
        {/* <Link to='/petruha-master' className="brands-nav-item">{brandsMenu.brands_petruha_master}</Link> */}
        {/* <Link to='/petruha-gotovo' className="brands-nav-item">{brandsMenu.brands_petruha_done}</Link> */}
        <Link to='/petruha-kolbasnye-izdeliya' className="brands-nav-item">{brandsMenu.brands_sausages}</Link>
      </nav>
      <h1 className="petruhaBrandWrapper_header">{info.title}</h1>
      <div className="petruhaBrandWrapper_content">
        {!isMobile && (
          <div
            className="first-col"
            style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.6) 100%), url(${info.image})` }}
            onClick={() => {
              if (info.video) {
                openPopup(info.video);
              }
            }}
          >
            <div className="play-wrapper">
              {
                info.video ? (
                  <div className="play-icon" />
                ) : null
              }
              <div className="play-description">{info.video_text}</div>
            </div>
          </div>
        )}
        <div className="second-col">
          <div className="top">
            <div className="descr">
              {ReactHtmlParser(info.text)}
            </div>
            <div className="small-header">
              {info.product_title}
            </div>
          </div>
          {!isMobile && (
            <Link to='/petruha-brand/products' className="bottom">
              <div className="flag">{buttons.button_whole_assortment}</div>
              {swiperElement}
            </Link>
          )}
        </div>
        <div className="third-col">
          <div className="image" style={{ backgroundImage: `url(${info.packing_image})` }} />
          {isMobile && (
            <Link to='/petruha-brand/products' className="bottom">
              <div className="flag">{buttons.button_whole_assortment}</div>
              {swiperElement}
            </Link>
          )}
        </div>
        {isMobile && (
          <div
            className="first-col"
            style={{ backgroundImage: `url(${info.image})` }}
            onClick={() => {
              if (info.video) {
                openPopup(info.video);
              }
            }}
          >
            <div className="play-wrapper">
              {
                info.video ? (
                  <div className="play-icon" />
                ) : null
              }
              <div className="play-description">{info.video_text}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    isMobile: state.ResizeReducer.isMobile,
    content: state.ContentReducer.content,
  }),
  (dispatch) => ({
    openPopup: (url) => {
      dispatch(VideoPopupActions.openVideoPopup(url));
    },
  }),
)(PetruhaBrandContent);
