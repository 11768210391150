import React from 'react';
import PropTypes from 'prop-types';

const Button = (props) => {
  const {
    children,
    text,
    onClick,
    type,
    classNameCustom,
    color,
  } = props;

  const clonedProps = {
    ...props,
    classNameCustom: null,
  };

  delete clonedProps.classNameCustom;
  delete clonedProps.color;

  return (
    <button
      onClick={onClick}
      className={`${type} ${color} ${classNameCustom}`}
      type='button'
      {...clonedProps}
    >
      {/* eslint-disable-next-line react/no-danger */}
      <span dangerouslySetInnerHTML={{ __html: text }}/>
      {children}
    </button>
  );
};

Button.defaultProps = {
  type: 'button',
  color: 'default',
  classNameCustom: '',
  onClick: () => {},
  disabled: false,
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  color: PropTypes.string,
  classNameCustom: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Button;
