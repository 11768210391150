import React from 'react';
import {Link} from 'react-router-dom';
import './burgerMenu.scss';

export default class BurgerMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      burgerIconClass: 'burger-icon',
      burgerWrapperClass: 'burgerWrapper',
    };
  }

  setAnimatedClass = () => {
    const { burgerIconClass } = this.state;
    if (burgerIconClass === 'burger-icon') {
      this.setState({
        burgerIconClass: 'burger-icon animated',
        burgerWrapperClass: 'burgerWrapper animated',
      });
    } else {
      this.setState({
        burgerIconClass: 'burger-icon',
        burgerWrapperClass: 'burgerWrapper',
      });
    }
  }

  render() {
    const { burgerIconClass, burgerWrapperClass } = this.state;
    const {
      mobileMenuColor,
      mobileMenuLinesColor,
      sideMenuTheme,
      menuItems,
      isMobile,
      brandItems,
    } = this.props;

    return (
      <>
        <div
          className={`${burgerWrapperClass}`}
          onClick={this.setAnimatedClass}
        >
          <div className={`burgerLine ${sideMenuTheme}`}>
            <Link className="menu-item" to='/'>{menuItems.menu_main_page}</Link>
            {!isMobile ? (
              <Link className="menu-item" to='/petruha-brand'>{menuItems.menu_brands}</Link>
            ) : (
              <>
                <Link className="menu-item" to='/petruha-brand'>{brandItems.brands_petruha}</Link>
                {/* <Link className="menu-item" to='/petruha-master'>{brandItems.brands_petruha_master}</Link> */}
                {/* <Link className="menu-item" to='/petruha-gotovo'>{brandItems.brands_petruha_done}</Link> */}
                <Link className="menu-item" to='/petruha-kolbasnye-izdeliya'>{brandItems.brands_sausages}</Link>
              </>
            )}
            <Link className="menu-item" to='/recipes'>{menuItems.menu_recipes}</Link>
            <Link className="menu-item" to='/petruha-online'>{menuItems.menu_petruha_online}</Link>
            <Link className="menu-item" to='/petruha-daily'>{menuItems.menu_food_for_thought}</Link>
            <Link className="menu-item" to='/promotions'>{menuItems.menu_news_and_promotions}</Link>
            <a className="menu-item" href='https://t.me/petruhaonline' target='_blank' rel="noreferrer">Петруха Online</a>
            <Link className="menu-item" to='/contacts'>{menuItems.menu_contacts}</Link>
          </div>
        </div>
        <div
          className={burgerIconClass}
          onClick={this.setAnimatedClass}
          // style={{backgroundColor: mobileMenuColor}}
          style={{backgroundColor: mobileMenuColor}}
        >
          <div style={{backgroundColor: mobileMenuLinesColor}} className="line"/>
          <div style={{backgroundColor: mobileMenuLinesColor}} className="line"/>
          <div style={{backgroundColor: mobileMenuLinesColor}} className="line"/>
        </div>
      </>
    );
  }
}
