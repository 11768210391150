import {
  createReducer,
  createHandlers,
} from '../reducerCreator';

import { ActionsConstants } from '../../../constants';

const initialState = {
  userIsAuthorized: false,
  userInfo: {},

  signInWithCookieInProcess: true,
  signUpInProcess: false,
  signInWithPasswordInProcess: false,
};

const handlers = createHandlers({
  [ActionsConstants.Auth.CHANGE_USER_INFO]: (state, { userInfo }) => ({
    ...state,
    userInfo,
  }),

  [ActionsConstants.Auth.SIGN_IN_WITH_PASSWORD_SUCCESS]: (state, { userInfo }) => ({
    ...state,
    signInWithPasswordInProcess: false,
    userIsAuthorized: true,
    userInfo,
  }),
  [ActionsConstants.Auth.SIGN_IN_WITH_PASSWORD_FAILED]: (state) => ({
    ...state,
    signInWithPasswordInProcess: false,
    userIsAuthorized: false,
    userInfo: {},
  }),
  [ActionsConstants.Auth.SIGN_IN_WITH_PASSWORD_IN_PROCESS]: (state, { signInWithPasswordInProcess }) => ({
    ...state,
    signInWithPasswordInProcess,
  }),

  [ActionsConstants.Auth.SIGN_IN_WITH_COOKIE_SUCCESS]: (state, { userInfo }) => ({
    ...state,
    signInWithCookieInProcess: false,
    userIsAuthorized: true,
    userInfo,
  }),
  [ActionsConstants.Auth.SIGN_IN_WITH_COOKIE_FAILED]: (state) => ({
    ...state,
    signInWithCookieInProcess: false,
    userIsAuthorized: false,
    userInfo: {},
  }),
  [ActionsConstants.Auth.SIGN_IN_WITH_COOKIE_IN_PROCESS]: (state, { signInWithCookieInProcess }) => ({
    ...state,
    signInWithCookieInProcess,
  }),

  [ActionsConstants.Auth.SIGN_UP_SUCCESS]: (state, { userInfo }) => ({
    ...state,
    signUpInProcess: false,
    userIsAuthorized: true,
    userInfo,
  }),
  [ActionsConstants.Auth.SIGN_UP_FAILED]: (state) => ({
    ...state,
    signUpInProcess: false,
  }),
  [ActionsConstants.Auth.SIGN_UP_IN_PROCESS]: (state, { signUpInProcess }) => ({
    ...state,
    signUpInProcess,
  }),

  [ActionsConstants.Auth.SIGN_OUT]: () => ({
    ...initialState,
    signInWithCookieInProcess: false,
  }),
});

export default (state = initialState, action) => createReducer(state, action, handlers);
