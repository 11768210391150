function lodaerSearchReducer(state = {message: ''}, action) {
  // debugger;
  switch (action.type) {
    case 'SET_MESSAGE': {
      return {
        message: action.message,
      };
    }
    default: {
      return state;
    }
  }
}

const setMessageCreateAction = (message) => (
  {
    type: 'SET_MESSAGE',
    message,
  }
);

export {setMessageCreateAction};
export default lodaerSearchReducer;
