import { ActionsConstants } from '../../../constants';

const chatWindowChangeState = (chatWindowState) => (dispatch) => {
  dispatch({
    type: ActionsConstants.Chat.CHAT_WINDOW_CHANGE,
    payload: chatWindowState,
  });
};

export default {
  chatWindowChangeState,
};
