import api from '../../../api';

import createAction from '../actionCreator';

import ActionsConstants from '../../../constants/actions';

const getSearchResultInProcessAction = (getSearchResultInProcess) => createAction(
  ActionsConstants.Search.SEARCH_RESULT_LOADING_IN_PROCESS,
  {
    getSearchResultInProcess,
  },
);
const getSearchResultSuccessAction = (result) => createAction(
  ActionsConstants.Search.SEARCH_RESULT_LOADING_SUCCESS,
  {
    result,
  },
);
const getSearchResultFailedAction = () => createAction(ActionsConstants.Search.SEARCH_RESULT_LOADING_FAILED);

// API
const getSearchResult = (query = '', page = 1) => (dispatch) => {
  dispatch(getSearchResultInProcessAction(true));
  api.get('search', {
    params: {
      query,
      page,
    },
  }).then(({ data = {} }) => {
    dispatch(getSearchResultSuccessAction(data));
  }).catch(() => {
    dispatch(getSearchResultFailedAction());
  });
};

export default {
  getSearchResultInProcessAction,
  getSearchResultSuccessAction,
  getSearchResultFailedAction,
  getSearchResult,
};
