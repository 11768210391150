import React from 'react';
import PropTypes from 'prop-types';

import { Scrollbars } from 'react-custom-scrollbars';

import { Button } from '../Buttons';

const ListItems = (
  {
    options,
    allowedOptions,
    onClickItem,
    withDisabledOptions,
  },
) => (
  <Scrollbars
    style={{ width: '100%' }}
    autoHeight
    autoHeightMax='10em'
  >
    {
      options.map((option) => (
        <div
          key={`key-select-${option.value}`}
          className='OptionItem'
        >
          <Button
            disabled={withDisabledOptions && !allowedOptions.includes(+option.value)}
            classNameCustom='select-option'
            text={option.label}
            onClick={() => onClickItem(option)}
          />
        </div>
      ))
    }
  </Scrollbars>
);

ListItems.defaultProps = {
  options: [],
  allowedOptions: [],
  onClickItem: () => {
  },
};

ListItems.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    iptTextOnSelect: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    payload: PropTypes.shape,
  })),
  allowedOptions: PropTypes.array,
  onClickItem: PropTypes.func,
};

export default ListItems;
