import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {Pagination} from 'swiper';
import './mealForBrain.scss';

SwiperCore.use([Pagination]);

const MealForBrainSlider = ({items = []}) => (
  <div className="swiperContainer2">
    <Swiper
      pagination={{ clickable: true }}
      id="mealForBrainSwiper"
      className="mealForBrainSwiper"
      loop="true"
    >
      {
        items && items.map((item) => (
          <SwiperSlide key={`meal-for-brain-slide-${item.id}`}>
            <div className="mainWrapper_col_second_meal-for-brain_slider">
              <div className="header">{item.title}</div>
              <div className="text_content">
                <div className="subheader">{item.text}</div>
              </div>
            </div>
          </SwiperSlide>
        ))
      }
    </Swiper>
  </div>
);

export default MealForBrainSlider;
