import { CommonConstants } from '../constants';

const textForNumber = (initNumber, aEndings) => {
  const iNumber = initNumber % 100;

  if (iNumber >= 11 && iNumber <= 19) {
    return aEndings[2];
  }

  const i = iNumber % 10;
  switch (i) {
    case (1):
      return aEndings[0];
    case (2):
    case (3):
    case (4):
      return aEndings[1];
    default:
      return aEndings[2];
  }
};

const isTextChanged = (oldValue, newValue) => Boolean(oldValue || newValue) && oldValue !== newValue;

const isTextNotChanged = (oldValue, newValue) => !isTextChanged(oldValue, newValue);

const clearSymbols = (string) => (string ? string.replace(/[^\d^/+]/g, '') : '');

const phoneFormat = (phone) => (phone ? phone.replace(CommonConstants.RegExp.PHONE, '+$1 ($2) $3 $4-$5') : '');

export default {
  textForNumber,
  isTextChanged,
  isTextNotChanged,
  clearSymbols,
  phoneFormat,
};
