import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet-async';
import petruha_image from '../../../assets/img/petruhaBrandContent/logo.svg';

const SEO = (props) => {
  const {
    title,
    description,
    keywords,
    ogTitle,
    ogDescription,
    ogUrl,
    ogImage,
    ogType,
    linkCanonical,
  } = props;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name='description' content={description.slice(0, 160)}/>
      <meta name='keywords' content={keywords} />
      <meta property='og:type' content={ogType} />
      <meta property='og:title' content={ogTitle} />
      <meta property="og:description" content={ogDescription.slice(0, 160)}/>
      <meta property='og:url' content={ogUrl} />
      <meta property='og:image' content={ogImage} />
      <link rel="canonical" href={linkCanonical} />
    </Helmet>
  );
};

SEO.defaultProps = {
  title: 'Петруха — официальный сайт производителя продукции из мяса птицы',
  description: 'Петруха официальный сайт производителя продукции из мяса птицы',
  keywords: 'Петруха,официальный сайт, производитель, рецепты, курица, мясо птицы',
  ogTitle: 'Петруха — официальный сайт производителя продукции из мяса птицы',
  ogDescription: 'Петруха официальный сайт производителя продукции из мяса птицы',
  ogUrl: `${window.location.href}`,
  ogImage: `${window.location.origin}${petruha_image}`,
  ogType: 'website',
  linkCanonical: `${window.location.href}`,
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  ogTitle: PropTypes.string,
  ogDescription: PropTypes.string,
  ogUrl: PropTypes.string,
  ogImage: PropTypes.string,
  ogType: PropTypes.string,
  linkCanonical: PropTypes.string,

};

export default SEO;
