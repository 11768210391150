import {
  createReducer,
  createHandlers,
} from '../reducerCreator';

import { ActionsConstants } from '../../../constants';

const initialState = {
  width: 0,
  height: 0,
  isMobile: false,
};

const handlers = createHandlers({
  [ActionsConstants.Resize.RESIZE]: (state, { width, height, isMobile }) => ({
    ...state,
    width,
    height,
    isMobile,
  }),
});

export default (state = initialState, action) => createReducer(state, action, handlers);
