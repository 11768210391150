import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Input from '../Inputs/Input';
import ListItems from './ListItems';

import './select.scss';

export default class Select extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      valueIpt: '',

      // selectedItem: null,
      showItems: false,
      filteredItems: [],
    };

    this.hovered = false;
  }

  componentDidMount() {
    const { options, defaultSelectOptionId } = this.props;

    if (defaultSelectOptionId !== -1 && options.length > 0) {
      this.setState({
        valueIpt: options.find((option) => defaultSelectOptionId === option.id).label,
        // selectedItem: options[defaultSelectOptionId],
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { clear } = this.props;

    if (prevProps.clear !== clear && clear) this.deselect();
  }

  getFilteredItems = (value) => {
    const { options, textInputAllowed } = this.props;

    return textInputAllowed ? options.filter((item) => item.label.toLowerCase().includes(value.toLowerCase())
      || value.toLowerCase().includes(item.label.toLowerCase())) : options;
  };

  clickItemHandler = (item) => {
    const { id, onSelectItem, onClose } = this.props;
    const iptValueText = item.iptTextOnSelect || item.label;
    const filteredItems = this.getFilteredItems(iptValueText);

    this.setState({
      valueIpt: iptValueText,
      showItems: false,
      // selectedItem: item,
      filteredItems,
    });

    onClose(iptValueText);
    onSelectItem(item, id);
  };

  changeInputHandler = ({ target: { value } }) => {
    const { id, onChangeInput, onClose } = this.props;
    const filteredItems = this.getFilteredItems(value);

    const isShowItems = filteredItems.length > 0;

    this.setState({
      valueIpt: value,
      showItems: isShowItems,
      filteredItems,
    });

    if (!isShowItems) {
      onClose(value);
    }

    onChangeInput(value, id);
  };

  renderItems = () => {
    const {
      toggled,
      options,
      textInputAllowed,
      withDisabledOptions,
      allowedOptions,
    } = this.props;
    const { filteredItems, showItems, valueIpt } = this.state;
    const showingOptions = options.filter((option) => option.label !== '');

    if ((showItems && toggled) || (showItems && valueIpt.length > 0)) {
      return (
        <div className='ListItems'>
          <ListItems
            withDisabledOptions={withDisabledOptions}
            allowedOptions={allowedOptions}
            options={textInputAllowed && valueIpt.length > 0 ? filteredItems : showingOptions}
            onClickItem={this.clickItemHandler}
          />
        </div>
      );
    }
    return <></>;
  };

  clickInputHandler = () => {
    const { toggled, onClose } = this.props;
    const { showItems, valueIpt } = this.state;

    if (toggled) {
      this.setState({
        showItems: !showItems,
      });

      if (showItems) {
        onClose(valueIpt);
      }
    }
  };

  selectItem = (optionId) => {
    const { options } = this.props;

    if (options.length > optionId) {
      const item = options[optionId];
      this.clickItemHandler(item);
    }
  };

  deselect = () => {
    this.setState({
      valueIpt: '',
      // selectedItem: undefined,
    });
  };

  render() {
    const {
      id,
      type,
      toggled,
      onBlurInput,
      textInputAllowed,
      placeholder,
      invalid,
      invalidMsg,
      label,
      labelPosition,
      disabled,
      onClose,
      customInputClass,
    } = this.props;

    const { showItems, valueIpt } = this.state;

    const selectOpened = (showItems && toggled) || (showItems && valueIpt.length > 0);

    return (
      <div
        className={classNames(selectOpened ? 'select-opened' : '', toggled ? 'toggled' : '')}
        onMouseLeave={() => {
          this.hovered = false;
        }}
        onMouseEnter={() => {
          this.hovered = true;
        }}
        onFocus={() => {
          this.hovered = true;
        }}
        onBlur={() => {
          if (!this.hovered) {
            onClose(valueIpt);

            this.setState({
              showItems: false,
            });
          }
        }}
      >
        <Input
          id={id}
          label={label}
          labelPosition={labelPosition}
          className={customInputClass ? `select ${customInputClass}` : 'select'}
          type={type}
          onBlur={onBlurInput}
          readOnly={!textInputAllowed}
          onClick={() => {
            if (!showItems) {
              this.clickInputHandler();
            }
          }}
          value={valueIpt}
          placeholder={placeholder}
          invalid={invalid}
          invalidMessage={invalidMsg}
          onChange={this.changeInputHandler}
          disabled={disabled}
        >
          {toggled && !disabled ? <span className='select-arrow'/> : ''}
        </Input>
        {this.renderItems()}
      </div>
    );
  }
}

Select.defaultProps = {
  id: '',
  placeholder: '',
  label: '',
  labelPosition: 'top',
  type: 'text',
  options: [],
  onChangeInput: () => {},
  onSelectItem: () => {},
  toggled: false,
  textInputAllowed: true,
  defaultSelectOptionId: -1,
  onBlurInput: () => {},
  onClose: () => {},
  disabled: false,
  customInputClass: '',

  invalid: false,
  invalidMsg: '',

  withDisabledOptions: false,
  allowedOptions: [],
};

Select.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  labelPosition: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    iptTextOnSelect: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    payload: PropTypes.shape,
  })),
  onChangeInput: PropTypes.func,
  onSelectItem: PropTypes.func,
  toggled: PropTypes.bool,
  textInputAllowed: PropTypes.bool,
  defaultSelectOptionId: PropTypes.number,
  onBlurInput: PropTypes.func,
  onClose: PropTypes.func,
  disabled: PropTypes.bool,
  customInputClass: PropTypes.string,

  invalid: PropTypes.bool,
  invalidMsg: PropTypes.string,

  withDisabledOptions: PropTypes.bool,
  allowedOptions: PropTypes.array,
};
