import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import './gotovoPageContent.scss';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { VideoPopupActions } from '../../redux/actions';

function GotovoPageContent(
  {
    contentBackground,
    openPopup,
    content: {
      petruhaGotovo: {
        info = {},
      } = {},
      brandsMenu,
      buttons,
    } = {},
    isMobile,
  },
) {
  // Из-за слайдеров страница на мобилке открывается посредине.
  window.scrollTo(0, 0);
  // const [opacity, setOpacity] = useState(0);

  return (
    // <div onScroll={function (e) { setOpacity(e.target.scrollTop * 0.03); }} className={`petruhaGotovoWrapper petruhaGotovoWrapper_${contentBackground}`}>
    <div className={`petruhaGotovoWrapper petruhaGotovoWrapper_${contentBackground}`}>
      {/* {isMobile ? (
        <Link
          className="logo"
          to='/'
        />
      ) : (
        <Link
          className="logo"
          to='/'
          style={{ opacity: `${1 - opacity}` }}
        />
      )} */}
      <Link
        className="logo"
        to='/petruha-gotovo/products'
      />
      <nav className='brandsNavigation'>
        <Link to='/petruha-brand' className="brands-nav-item">{brandsMenu.brands_petruha}</Link>
        <Link to='/petruha-master' className="brands-nav-item">{brandsMenu.brands_petruha_master}</Link>
        <Link to='/petruha-gotovo' className="brands-nav-item">{brandsMenu.brands_petruha_done}</Link>
        <Link to='/petruha-kolbasnye-izdeliya' className="brands-nav-item">{brandsMenu.brands_sausages}</Link>
      </nav>
      <h1 className="petruhaGotovoWrapper_header">{info.title}</h1>
      {!isMobile && (
        <h2 className="petruhaGotovoWrapper_subheader">{info.subtitle}</h2>
      )}
      <div className="petruhaGotovoWrapper_content">
        {!isMobile && (
          <div
            className="first-col"
            style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.6) 100%), url(${info.image})` }}
            onClick={() => {
              if (info.video) {
                openPopup(info.video);
              }
            }}
          >
            <div className="items-text" />
            <div className="play-wrapper">
              {
                info.video ? (
                  <div className="play-icon" />
                ) : null
              }
              <div className="play-description">{info.video_text}</div>
            </div>
          </div>
        )}
        <div className="second-col">
          <div className="top">
            <div className="descr">
              <div className="text">
                {ReactHtmlParser(info.text)}
              </div>
            </div>
            <div className="picture">
              <div className="squinting-picture" style={{ backgroundImage: `url(${info.packing_image})` }} />
            </div>
          </div>
          <Link to='/petruha-gotovo/products' className="bottom">
            <div className="flag">{buttons.button_whole_assortment}</div>
            <div className="assorti-swiper-container">
              <div className="text">
                {ReactHtmlParser(info.product_text)}
              </div>
            </div>
            <div className="tasty-dish" style={{ backgroundImage: `url(${info.product_image})` }} />
          </Link>
        </div>
        {isMobile && (
          <div
            className="first-col"
            style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.6) 100%), url(${info.image})` }}
            onClick={() => {
              if (info.video) {
                openPopup(info.video);
              }
            }}
          >
            <div className="items-text" />
            <div className="play-wrapper">
              {
                info.video ? (
                  <div className="play-icon" />
                ) : null
              }
              <div className="play-description">{info.video_text}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    isMobile: state.ResizeReducer.isMobile,
    content: state.ContentReducer.content,
  }),
  (dispatch) => ({
    openPopup: (url) => {
      dispatch(VideoPopupActions.openVideoPopup(url));
    },
  }),
)(GotovoPageContent);
