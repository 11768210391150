import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './petruhaMasterContent.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Mousewheel, Pagination, Keyboard,
} from 'swiper/core';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { VideoPopupActions } from '../../redux/actions';

SwiperCore.use([Mousewheel, Pagination, Keyboard]);

function PetruhaMasterContent(
  {
    contentBackground,
    openPopup,
    content: {
      petruhaMaster: {
        info = {},
      } = {},
      brandsMenu,
      buttons,
    } = {},
    isMobile,
  },
) {
  const dailyPetruha = info.gallery ? info.gallery.map((item) => (
    <SwiperSlide key={`petruha-master-products-${item.id}`}>
      <div
        className="cover"
        style={{ backgroundImage: `url(${item.image})` }}
      />
    </SwiperSlide>
  )) : null;

  const swiperElement = (
    <Swiper
      className="petruhaMasterSwiper"
      id="petruhaMasterSwiper"
      loop="true"
      setWrapperSize="true"
      pagination={{ clickable: true }}
      onAfterInit={(swiper) => {
        swiper.update();
      }}
      slidesPerView="1"
      keyboard={{
        enabled: true,
        onlyInViewport: true,
      }}
    >
      {dailyPetruha}
    </Swiper>
  );
  // Из-за слайдеров страница на мобилке открывается посредине.
  window.scrollTo(0, 0);
  // const [opacity, setOpacity] = useState(0);

  return (
    // <div onScroll={function (e) { setOpacity(e.target.scrollTop * 0.03); }} className={`petruhaMasterWrapper petruhaMasterWrapper_${contentBackground}`}>
    <div className={`petruhaMasterWrapper petruhaMasterWrapper_${contentBackground}`}>

      {/* {isMobile ? (
        <Link
          className="logo"
          to='/'
        />
      ) : (
        <Link
          className="logo"
          to='/'
          style={{ opacity: `${1 - opacity}` }}
        />
      )} */}
      <Link
        className="logo"
        to='/petruha-master/products'
      />
      <nav className='brandsNavigation'>
        <Link to='/petruha-brand' className="brands-nav-item">{brandsMenu.brands_petruha}</Link>
        <Link to='/petruha-master' className="brands-nav-item">{brandsMenu.brands_petruha_master}</Link>
        <Link to='/petruha-gotovo' className="brands-nav-item">{brandsMenu.brands_petruha_done}</Link>
        <Link to='/petruha-kolbasnye-izdeliya' className="brands-nav-item">{brandsMenu.brands_sausages}</Link>
      </nav>
      <h1 className="petruhaMasterWrapper_header">{info.title}</h1>
      <div className="petruhaMasterWrapper_content">
        {!isMobile ? (
          <>
            <div className="first-col">
              <div className="image" style={{ backgroundImage: `url(${info.packing_image})` }} />
            </div>
            <div className="second-col">
              <div className="top">
                <div className="descr">
                  {ReactHtmlParser(info.text)}
                </div>
              </div>
              <div className="bottom">
                <Link to='/petruha-master/products' className="flag">{buttons.button_whole_assortment}</Link>
                {swiperElement}
              </div>
            </div>
            <div
              className="third-col"
              style={{ backgroundImage: `linear-gradient(to bottom, rgba(0,0,0, .5) 0%, rgba(0,0,0, .6) 100%), url(${info.image})` }}
              onClick={() => {
                if (info.video) {
                  openPopup(info.video);
                }
              }}
            >
              <div className="play-wrapper">
                {
                  info.video ? (
                    <div className="play-icon" />
                  ) : null
                }
                <div className="play-description">{info.video_text}</div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="second-col">
              <div className="top">
                <div className="descr">
                  {ReactHtmlParser(info.text)}
                </div>
              </div>
            </div>
            <div className="first-col">
              <div className="image" style={{ backgroundImage: `url(${info.packing_image})` }} />
              <div className="bottom">
                <Link to='/petruha-master/products' className="flag">{buttons.button_whole_assortment}</Link>
              </div>
            </div>
            <div
              className="third-col"
              style={{ backgroundImage: `linear-gradient(to bottom, rgba(0,0,0, .5) 0%, rgba(0,0,0, .6) 100%), url(${info.image})` }}
              onClick={() => {
                if (info.video) {
                  openPopup(info.video);
                }
              }}
            >
              <div className="play-wrapper">
                {
                  info.video ? (
                    <div className="play-icon" />
                  ) : null
                }
                <div className="play-description">{info.video_text}</div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    isMobile: state.ResizeReducer.isMobile,
    content: state.ContentReducer.content,
  }),
  (dispatch) => ({
    openPopup: (url) => {
      dispatch(VideoPopupActions.openVideoPopup(url));
    },
  }),
)(PetruhaMasterContent);
