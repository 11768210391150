import React from 'react';
import './footer.scss';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';

import {
  ChatWindowAction,
} from '../../redux/actions';
import textUtils from '../../utils/textUtils';

class Footer extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const {
      firstLoad,
      footerBackground,
      footerTheme,
      content: {
        footer,
      } = {},
      isChatOpen,
      changeWindowState,
    } = this.props;
    const domain = window.location.hostname;

    return (
      <div className={footerTheme ? `footer ${footerTheme} ${isChatOpen && window.innerWidth < 500 && 'chatbot_open'}` : `footer ${isChatOpen && window.innerWidth < 500 && 'chatbot_open'}`} style={{background: footerBackground}}>
        <div className="footer_item footer_contacts">
          <p>© {footer.copyright}</p>
          <p>{footer.phone_text} <a href={`tel:${textUtils.clearSymbols(footer.phone)}`}>{footer.phone}</a></p>
          <p>{footer.email_text} <a href={`mailto:${footer.email}`}>{footer.email}</a></p>
        </div>
        <div className="footer_item centered">
          <div className="footer_social-icons">
            {
              domain === process.env.REACT_APP_DOMAIN_RU ? (
                <>
                  <a className="footer_social-icons_social-icon vk" href={footer.vk} target='_blank' rel="noreferrer"> </a>
                  <a className="footer_social-icons_social-icon ok" href={footer.ok} target='_blank' rel="noreferrer"> </a>
                  <a className="footer_social-icons_social-icon youtube" href={footer.youtube} target='_blank' rel="noreferrer"> </a>
                  <a className="footer_social-icons_social-icon tiktok" href={footer.tiktok} target='_blank' rel="noreferrer"> </a>
                </>
              ) : (
                <>
                  <a className="footer_social-icons_social-icon fb" href={footer.facebook} target='_blank' rel="noreferrer"> </a>
                  <a className="footer_social-icons_social-icon instagram" href={footer.instagram} target='_blank' rel="noreferrer"> </a>
                  <a className="footer_social-icons_social-icon youtube" href={footer.youtube} target='_blank' rel="noreferrer"> </a>
                  <a className="footer_social-icons_social-icon tiktok" href={footer.tiktok} target='_blank' rel="noreferrer"> </a>
                </>
              )
            }
          </div>
          {/* {
            domain !== process.env.REACT_APP_DOMAIN_COM ? (
              <div
                id="chatBot"
                onClick={() => {
                  if (window.botmanChatWidget.widget.state.isChatOpen) {
                    window.botmanChatWidget.close();
                    changeWindowState(window.botmanChatWidget.widget.state.isChatOpen);
                  } else {
                    window.botmanChatWidget.open();
                    changeWindowState(window.botmanChatWidget.widget.state.isChatOpen);
                  }
                }}
                className="footer_item footer_item_dialog"
              />
            ) : null
          } */}
        </div>
        {/* <div className="footer_item footer_languages">
          {
            domain !== process.env.REACT_APP_DOMAIN_COM ? (
              <div className="footer_languages_item current">rus</div>
            ) : (
              <a className="footer_languages_item" href={`https://${process.env.REACT_APP_DOMAIN_RU}`}>rus</a>
            )
          }
          <div className="footer_languages_item divider">|</div>
          {
            domain === process.env.REACT_APP_DOMAIN_COM ? (
              <div className="footer_languages_item current">eng</div>
            ) : (
              <a className="footer_languages_item" href={`https://${process.env.REACT_APP_DOMAIN_COM}`}>eng</a>
            )
          }
        </div> */}
        <div className="footer_item footer_with-love">
          <p>
            <a href="./policy.pdf" target="_blank">Политика обработки</a>
          </p>
          <p>
            <a href="./cookies.pdf" target="_blank">Политика cookies</a>
          </p>
          {/*
            {ReactHtmlParser(footer.with_love)}
            <p className="plus">+</p>
          */}
        </div>
        { firstLoad
          && (
            <>
              <div className="footer_line_horizontal_top"/>
              <div className="footer_animated_fill"/>
            </>
          )}

      </div>
    );
  }
}

export default connect(
  (state) => ({
    firstLoad: state.FirstLoadReducer.firstLoad,
    content: state.ContentReducer.content,
    isChatOpen: state.ChatWindowReducer.isChatOpen,
  }),
  (dispatch) => ({
    changeWindowState: (chatWindowState) => {
      dispatch(ChatWindowAction.chatWindowChangeState(chatWindowState));
    },
  }),
)(Footer);
