import React from 'react';
import PropTypes from 'prop-types';

export default class ErrorInputMessage extends React.Component {
  render() {
    const { invalid, invalidMessage } = this.props;

    if (invalid) {
      return (
        <div className='error-msg'>{invalidMessage}</div>
      );
    }

    return ('');
  }
}

ErrorInputMessage.defaultProps = {
  invalid: false,
  invalidMessage: '',
};

ErrorInputMessage.propTypes = {
  invalid: PropTypes.bool,
  invalidMessage: PropTypes.string,
};
