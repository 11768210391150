import React, {useEffect} from 'react';
import PetruhaOnlineContent from '../../components/PetruhaOnlineContent';
import BasePageLayout from '../../components/layouts/BasePageLayout';
import SEO from '../../components/SEO';

const PetruhaOnlinePage = (
  {
    location: {
      hash,
    },
    lang,
  },
) => {
  const titleRu = 'О компании Петруха — этапы производства и интервью с экспертами';
  const titleEn = 'About Petruha - production stages and interviews with experts';

  useEffect(() => {
    document.title = lang === 'en' ? titleEn : titleRu;
  });
  return (
    <div>
      <BasePageLayout
        overflowProp='auto'
        displayProp='block'
        marginProp='-5px'
      >
        <SEO
          description={lang === 'en' ? titleEn : titleRu}
          ogDescription={lang === 'en' ? titleEn : titleRu}
          keywords='Петруха, интервью, этапы производство, эксперты'
          ogTitle={lang === 'en' ? titleEn : titleRu}
          title={lang === 'en' ? titleEn : titleRu}
        />
        <PetruhaOnlineContent hash={hash}/>
      </BasePageLayout>
    </div>
  );
};

export default PetruhaOnlinePage;
