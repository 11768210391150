import React from 'react';
import './aside.scss';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import { FirstLoadActions } from '../../redux/actions';
import History from '../../utils/History';
import {setMessageCreateAction} from '../../redux/reducers/common/lodaer_search';

class Aside extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchPanelFlag: false,
      searchText: '',
      searchPage: false,
    };
    this.searchRef = React.createRef();
  }

  componentDidMount() {
    if (window.location.href.indexOf('/search') + 1) {
      const { loaderSearch: { message } } = this.props;
      this.setState({searchPanelFlag: true});
      this.setState({searchText: message});
      this.setState({searchPage: true});
      this.hideSearchPanel();
    }
    const {loadFirst} = this.props;
    setTimeout(() => {
      loadFirst(false);
    }, 5000);
  }

  showSearchPanel = () => {
    this.setState({
      searchPanelFlag: true,
    });
  }

  hideSearchPanel = () => {
    this.setState({
      searchPanelFlag: false,
    });
  }

  keyDownHandler = (e) => {
    if (e.key === 'Enter') {
      const { searchText } = this.state;
      const { setMessage } = this.props;
      setMessage(searchText);
      History.getInstance().history.push(`/search?q=${searchText}`);
    }
  }

  render() {
    const {
      firstLoad,
      asideBackground,
      asideTheme,
      content: {
        buttons,
      } = {},
      setMessage,
    } = this.props;
    const { searchPanelFlag, searchText, searchPage } = this.state;
    return (
      <div className={asideTheme ? `aside ${asideTheme}` : 'aside'} style={{background: asideBackground}}>
        <div
          onClick={() => { this.hideSearchPanel(); }}
          className={!searchPanelFlag ? 'aside_search-hidden-wrapper' : 'aside_search-hidden-wrapper active'}
        />
        <div className={`${!searchPanelFlag ? 'aside_search-hidden' : 'aside_search-hidden active'} ${searchPage ? 'searchpage' : ''}`}>
          {/* eslint-disable-next-line */}
          <label htmlFor="search-input-main" className="input-icon"/>
          {/* eslint-disable-next-line */}
          <input type="text" id="search-input-main" value={searchText} ref={this.searchRef} onChange={(e) => this.setState({searchText: e.target.value})} onKeyDown={this.keyDownHandler}/>
          <Link to={`/search?q=${searchText}`} onClick={() => { setMessage(searchText); }} className="search-button">{buttons.button_search}</Link>
        </div>
        <div
          onClick={() => { this.showSearchPanel(); this.searchRef.current.focus(); }}
          className={!searchPanelFlag ? 'aside_search' : 'aside_search hidden'}
        />
        {/* <div className="aside_plus">+</div> */}
        <div className="aside_logo">
          <Link to='/' className="aside_logo-tablet" />
        </div>
        { firstLoad
        && (
        <>
          <div className="aside_animated_fill"/>
        </>
        )}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    content: state.ContentReducer.content,
    firstLoad: state.FirstLoadReducer.firstLoad,
    loaderSearch: state.lodaerSearchReducer,
  }),
  (dispatch) => ({
    loadFirst: (firstLoad) => {
      dispatch(FirstLoadActions.loadFirst(firstLoad));
    },
    setMessage: (message) => {
      dispatch(setMessageCreateAction(message));
    },
  }),
)(Aside);
