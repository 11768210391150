import api from '../../api';

import createAction from './actionCreator';

import ActionsConstants from '../../constants/actions';

const getContentInProcessAction = (getContentInProcess) => createAction(
  ActionsConstants.Content.CONTENT_LOADING_IN_PROCESS,
  {
    getContentInProcess,
  },
);
const getContentSuccessAction = (content) => createAction(
  ActionsConstants.Content.CONTENT_LOADING_SUCCESS,
  {
    content,
  },
);
const getContentFailedAction = () => createAction(ActionsConstants.Content.CONTENT_LOADING_FAILED);

const getRecipesInProcessAction = (getRecipesInProcess) => createAction(
  ActionsConstants.Content.RECIPES_LOADING_IN_PROCESS,
  {
    getRecipesInProcess,
  },
);
const getRecipesSuccessAction = (recipes) => createAction(
  ActionsConstants.Content.RECIPES_LOADING_SUCCESS,
  {
    recipes,
  },
);
const getRecipesFailedAction = () => createAction(ActionsConstants.Content.RECIPES_LOADING_FAILED);

const getRecipesContentInProcessAction = (getRecipesContentInProcess) => createAction(
  ActionsConstants.Content.RECIPES_CONTENT_LOADING_IN_PROCESS,
  {
    getRecipesContentInProcess,
  },
);
const getRecipesContentSuccessAction = (data) => createAction(
  ActionsConstants.Content.RECIPES_CONTENT_LOADING_SUCCESS,
  {
    data,
  },
);
const getRecipesContentFailedAction = () => createAction(ActionsConstants.Content.RECIPES_CONTENT_LOADING_FAILED);

const getDetailRecipeInProcessAction = (getDetailRecipeInProcess) => createAction(
  ActionsConstants.Content.DETAIL_RECIPE_LOADING_IN_PROCESS,
  {
    getDetailRecipeInProcess,
  },
);
const getDetailRecipeSuccessAction = (recipe) => createAction(
  ActionsConstants.Content.DETAIL_RECIPE_LOADING_SUCCESS,
  {
    recipe,
  },
);
const getDetailRecipeFailedAction = () => createAction(ActionsConstants.Content.DETAIL_RECIPE_LOADING_FAILED);

const getNewsInProcessAction = (getNewsInProcess) => createAction(
  ActionsConstants.Content.NEWS_LOADING_IN_PROCESS,
  {
    getNewsInProcess,
  },
);
const getNewsSuccessAction = (news) => createAction(
  ActionsConstants.Content.NEWS_LOADING_SUCCESS,
  {
    news,
  },
);
const getNewsFailedAction = () => createAction(ActionsConstants.Content.NEWS_LOADING_FAILED);

const getDetailNewsInProcessAction = (getDetailNewsInProcess) => createAction(
  ActionsConstants.Content.DETAIL_NEWS_LOADING_IN_PROCESS,
  {
    getDetailNewsInProcess,
  },
);
const getDetailNewsSuccessAction = (news) => createAction(
  ActionsConstants.Content.DETAIL_NEWS_LOADING_SUCCESS,
  {
    news,
  },
);
const getDetailNewsFailedAction = () => createAction(ActionsConstants.Content.DETAIL_NEWS_LOADING_FAILED);

// API
const getContent = () => (dispatch) => {
  dispatch(getContentInProcessAction(true));
  api.get('content').then(({ data = {} }) => {
    dispatch(getContentSuccessAction(data));
  }).catch(() => {
    dispatch(getContentFailedAction());
  });
};

const getRecipes = (filters = {}) => (dispatch) => new Promise((resolve, reject) => {
  dispatch(getRecipesInProcessAction(true));
  api.get('recipes', {
    params: {
      ...filters,
    },
  }).then(({ data = {} }) => {
    dispatch(getRecipesSuccessAction(data));
    resolve(data);
  }).catch(() => {
    dispatch(getRecipesFailedAction());
    reject();
  });
});

const getRecipesContent = () => (dispatch) => {
  dispatch(getRecipesContentInProcessAction(true));
  api.get('recipes-content').then(({ data = {} }) => {
    dispatch(getRecipesContentSuccessAction(data));
  }).catch(() => {
    dispatch(getRecipesContentFailedAction());
  });
};

const getDetailRecipe = (slug) => (dispatch) => {
  dispatch(getDetailRecipeInProcessAction(true));
  api.get(`recipes/${slug}`).then(({ data = null }) => {
    dispatch(getDetailRecipeSuccessAction(data));
  }).catch(() => {
    dispatch(getDetailRecipeFailedAction());
  });
};

const getNews = () => (dispatch) => {
  dispatch(getNewsInProcessAction(true));
  api.get('news').then(({ data = [] }) => {
    dispatch(getNewsSuccessAction(data));
  }).catch(() => {
    dispatch(getNewsFailedAction());
  });
};

const getDetailNews = (slug) => (dispatch) => {
  dispatch(getDetailNewsInProcessAction(true));
  api.get(`news/${slug}`).then(({ data = {} }) => {
    dispatch(getDetailNewsSuccessAction(data));
  }).catch(() => {
    dispatch(getDetailNewsFailedAction());
  });
};

export default {
  getContentInProcessAction,
  getContentSuccessAction,
  getContentFailedAction,
  getContent,

  getRecipesInProcessAction,
  getRecipesSuccessAction,
  getRecipesFailedAction,
  getRecipes,

  getNewsInProcessAction,
  getNewsSuccessAction,
  getNewsFailedAction,
  getNews,

  getDetailNewsInProcessAction,
  getDetailNewsSuccessAction,
  getDetailNewsFailedAction,
  getDetailNews,

  getRecipesContentInProcessAction,
  getRecipesContentSuccessAction,
  getRecipesContentFailedAction,
  getRecipesContent,

  getDetailRecipeInProcessAction,
  getDetailRecipeSuccessAction,
  getDetailRecipeFailedAction,
  getDetailRecipe,
};
