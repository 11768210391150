import {
  createReducer,
  createHandlers,
} from '../reducerCreator';

import { ActionsConstants } from '../../../constants';

const initialState = {
  isChatOpen: false,
};

const handlers = createHandlers({
  [ActionsConstants.Chat.CHAT_WINDOW_CHANGE]: (state, isChatOpen) => (
    {
      ...state,
      isChatOpen,
    }
  ),
});

export default (state = initialState, action) => createReducer(state, action, handlers);
