export default {
  CONTENT_LOADING_IN_PROCESS: 'CONTENT_LOADING_IN_PROCESS',
  CONTENT_LOADING_SUCCESS: 'CONTENT_LOADING_SUCCESS',
  CONTENT_LOADING_FAILED: 'CONTENT_LOADING_FAILED',

  RECIPES_LOADING_IN_PROCESS: 'RECIPES_LOADING_IN_PROCESS',
  RECIPES_LOADING_SUCCESS: 'RECIPES_LOADING_SUCCESS',
  RECIPES_LOADING_FAILED: 'RECIPES_LOADING_FAILED',

  RECIPES_CONTENT_LOADING_IN_PROCESS: 'RECIPES_CONTENT_LOADING_IN_PROCESS',
  RECIPES_CONTENT_LOADING_SUCCESS: 'RECIPES_CONTENT_LOADING_SUCCESS',
  RECIPES_CONTENT_LOADING_FAILED: 'RECIPES_CONTENT_LOADING_FAILED',

  DETAIL_RECIPE_LOADING_IN_PROCESS: 'DETAIL_RECIPE_LOADING_IN_PROCESS',
  DETAIL_RECIPE_LOADING_SUCCESS: 'DETAIL_RECIPE_LOADING_SUCCESS',
  DETAIL_RECIPE_LOADING_FAILED: 'DETAIL_RECIPE_LOADING_FAILED',

  NEWS_LOADING_IN_PROCESS: 'NEWS_LOADING_IN_PROCESS',
  NEWS_LOADING_SUCCESS: 'NEWS_LOADING_SUCCESS',
  NEWS_LOADING_FAILED: 'NEWS_LOADING_FAILED',

  DETAIL_NEWS_LOADING_IN_PROCESS: 'DETAIL_NEWS_LOADING_IN_PROCESS',
  DETAIL_NEWS_LOADING_SUCCESS: 'DETAIL_NEWS_LOADING_SUCCESS',
  DETAIL_NEWS_LOADING_FAILED: 'DETAIL_NEWS_LOADING_FAILED',
};
