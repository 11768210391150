import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import BasePageLayout from '../../../components/layouts/BasePageLayout';
import GotovoPageContent from '../../../components/GotovoPageContent';
import SEO from '../../../components/SEO';

const PetruhaGotovoPage = ({isMobile, lang}) => {
  const titleRu = 'Петруха Готово — готовые запечённые части птицы';
  const titleEn = 'Ready - ready-made roasted poultry parts';
  useEffect(() => {
    document.title = lang === 'en' ? titleEn : titleRu;
  });
  return (
    <>
      <BasePageLayout
        layoutBackground='linear-gradient(115deg, #79279d 1.72%, #c389dc 68.58%)'
        asideBackground='transparent'
        footerBackground={isMobile ? 'transparent' : 'linear-gradient(130deg, rgb(146 73 178) 1.72%, rgb(195, 137, 220) 68.58%)'}
        footerTheme={!isMobile ? 'green' : ''}
        asideTheme='green'
        mobileMenuColor={isMobile ? '' : 'rgba(255, 255, 255, 0.1)'}
        mobileMenuLinesColor='#ffffff'
        sideMenuTheme='green'
      >
        <SEO
          description={lang === 'en' ? titleEn : titleRu}
          ogDescription={lang === 'en' ? titleEn : titleRu}
          keywords='Бренд, Петруха, продукция'
          ogTitle={lang === 'en' ? titleEn : titleRu}
          title={lang === 'en' ? titleEn : titleRu}
        />
        <GotovoPageContent/>
      </BasePageLayout>
    </>
  );
};

export default connect(
  (state) => ({
    isMobile: state.ResizeReducer.isMobile,
  }),
)(PetruhaGotovoPage);
