export default {
  MAIN_PAGE_ROUTE: '/',
  // RECIPES_PAGE_FILTER_EVENT_ROUTE: '/recipes/f/:filter',
  RECIPES_PAGE_ROUTE: '/recipes',
  RECIPE_PAGE_ROUTE: '/recipes/:id',
  PETRUHA_ONLINE_PAGE_ROUTE: '/petruha-online',
  BRANDS_PETRUHA_PAGE_ROUTE: '/petruha-brand',
  PETRUHA_PRODUCTS_PAGE_ROUTE: '/petruha-brand/products',
  BRANDS_PETRUHA_MASTER_PAGE_ROUTE: '/petruha-master',
  PETRUHA_MASTER_PRODUCTS_PAGE_ROUTE: '/petruha-master/products',
  BRANDS_PETRUHA_MKI_PAGE_ROUTE: '/petruha-kolbasnye-izdeliya',
  PETRUHA_MKI_PRODUCTS_PAGE_ROUTE: '/petruha-kolbasnye-izdeliya/products',
  BRANDS_PETRUHA_GOTOVO_PAGE_ROUTE: '/petruha-gotovo',
  PETRUHA_GOTOVO_PRODUCTS_PAGE_ROUTE: '/petruha-gotovo/products',
  PROMOTIONS_PAGE_ROUTE: '/promotions',
  PROMOTION_PAGE_ROUTE: '/promotions/:id',
  CONTACTS_PAGE_ROUTE: '/contacts',
  SEARCH_PAGE_ROUTE: '/search',
  TEST_PAGE_ROUTE: '/check-search',
  EXPERT_PAGE_ROUTE: '/petruha-online/experts/:id',
  BOT_PAGE: '/bot',
  DAILY_PAGE_ROUTE: '/petruha-daily',
};
