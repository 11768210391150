import {
  createReducer,
  createHandlers,
} from './reducerCreator';

import { ActionsConstants } from '../../constants';

const initialState = {
  getContentInProcess: true,
  contentLoadingFromAPI: false,
  content: {},
  news: [],
  detailNews: {},
  recipesContent: {},
  detailRecipe: null,
  getDetailRecipeInProcess: true,
  getDetailNewsInProcess: true,
};

const handlers = createHandlers({
  [ActionsConstants.Content.CONTENT_LOADING_IN_PROCESS]: (state, { getContentInProcess }) => ({
    ...state,
    getContentInProcess,
  }),
  [ActionsConstants.Content.CONTENT_LOADING_SUCCESS]: (state, {
    content,
  }) => ({
    ...state,
    getContentInProcess: false,
    contentLoadingFromAPI: true,
    content,
  }),
  [ActionsConstants.Content.CONTENT_LOADING_FAILED]: (state) => ({
    ...state,
    content: {},
    getContentInProcess: false,
    contentLoadingFromAPI: false,
  }),

  [ActionsConstants.Content.RECIPES_LOADING_IN_PROCESS]: (state, { getRecipesInProcess }) => ({
    ...state,
    getRecipesInProcess,
  }),
  [ActionsConstants.Content.RECIPES_LOADING_SUCCESS]: (state, {
    recipes,
  }) => ({
    ...state,
    getRecipesInProcess: false,
    recipes,
  }),
  [ActionsConstants.Content.RECIPES_LOADING_FAILED]: (state) => ({
    ...state,
    getRecipesInProcess: false,
  }),

  [ActionsConstants.Content.RECIPES_CONTENT_LOADING_IN_PROCESS]: (state, { getRecipesContentInProcess }) => ({
    ...state,
    getRecipesContentInProcess,
  }),
  [ActionsConstants.Content.RECIPES_CONTENT_LOADING_SUCCESS]: (state, {
    data,
  }) => ({
    ...state,
    getRecipesContentInProcess: false,
    recipesContent: data,
  }),
  [ActionsConstants.Content.RECIPES_CONTENT_LOADING_FAILED]: (state) => ({
    ...state,
    getRecipesContentInProcess: false,
  }),

  [ActionsConstants.Content.DETAIL_RECIPE_LOADING_IN_PROCESS]: (state, { getDetailRecipeInProcess }) => ({
    ...state,
    getDetailRecipeInProcess,
  }),
  [ActionsConstants.Content.DETAIL_RECIPE_LOADING_SUCCESS]: (state, {
    recipe,
  }) => ({
    ...state,
    getDetailRecipeInProcess: false,
    detailRecipe: recipe,
  }),
  [ActionsConstants.Content.DETAIL_RECIPE_LOADING_FAILED]: (state) => ({
    ...state,
    getDetailRecipeInProcess: false,
  }),

  [ActionsConstants.Content.NEWS_LOADING_IN_PROCESS]: (state, { getNewsInProcess }) => ({
    ...state,
    getNewsInProcess,
  }),
  [ActionsConstants.Content.NEWS_LOADING_SUCCESS]: (state, {
    news,
  }) => ({
    ...state,
    getNewsInProcess: false,
    news,
  }),
  [ActionsConstants.Content.NEWS_LOADING_FAILED]: (state) => ({
    ...state,
    getNewsInProcess: false,
  }),

  [ActionsConstants.Content.DETAIL_NEWS_LOADING_IN_PROCESS]: (state, { getDetailNewsInProcess }) => ({
    ...state,
    getDetailNewsInProcess,
  }),
  [ActionsConstants.Content.DETAIL_NEWS_LOADING_SUCCESS]: (state, {
    news,
  }) => ({
    ...state,
    getDetailNewsInProcess: false,
    detailNews: news,
  }),
  [ActionsConstants.Content.DETAIL_NEWS_LOADING_FAILED]: (state) => ({
    ...state,
    getDetailNewsInProcess: false,
  }),
});

export default (state = initialState, action) => createReducer(state, action, handlers);
