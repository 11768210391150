import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import BasePageLayout from '../../components/layouts/BasePageLayout';
import PromotionsContent from '../../components/PromotionsContent';
import SEO from '../../components/SEO';
// import ResizeReducer from '../../redux/reducers/common/resize';

const PromotionsPage = ({isMobile, lang}) => {
  const titleRu = 'Новости от Петруха — будь в курсе последних новостей и акций производства';
  const titleEn = 'News from Petruha - stay up to date with the latest news and production promotions';
  useEffect(() => {
    document.title = lang === 'en' ? titleEn : titleRu;
  });
  return (
    <div>
      <BasePageLayout
        overflowProp='auto'
        displayProp='block'
        marginProp='-5px'
        mobileMenuColor={isMobile ? '' : '#cccccc60'}
        mobileMenuLinesColor='#ffffff'
      >
        <SEO
          description={lang === 'en' ? titleEn : titleRu}
          ogDescription={lang === 'en' ? titleEn : titleRu}
          keywords='Новости, Петруха, продукция'
          ogTitle={lang === 'en' ? titleEn : titleRu}
          title={lang === 'en' ? titleEn : titleRu}
        />
        <PromotionsContent/>
      </BasePageLayout>
    </div>
  );
};

export default connect(
  (state) => ({
    isMobile: state.ResizeReducer.isMobile,
  }),
)(PromotionsPage);
