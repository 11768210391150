import React from 'react';
import PropTypes from 'prop-types';

import ErrorInputMessage from '../inputComponents/ErrorInputMessage';
import InputLabel from '../inputComponents/InputLabel';

export default class Input extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  onBlurEvent = (event) => {
    const { onBlur } = this.props;

    onBlur(event);
  };

  render() {
    const {
      id,
      className,
      invalid,
      invalidMessage,
      label,
      labelPosition,
      children,
    } = this.props;

    const inputProps = { ...this.props };

    delete inputProps.invalid;
    delete inputProps.invalidMessage;
    delete inputProps.emptyField;
    delete inputProps.className;
    delete inputProps.children;
    delete inputProps.id;
    delete inputProps.onBlur;
    delete inputProps.labelPosition;

    return (
      <div className={`input-block label-position__${labelPosition} ${invalid ? 'inputError' : ''}`}>
        {
          (label)
          && (
            <InputLabel text={label} inputId={id}/>
          )
        }
        <div className='input'>
          <input
            id={id}
            className={className}
            {...inputProps}
            autoComplete='new-off'
            onBlur={(event) => this.onBlurEvent(event)}
          />
          {children && (children)}
          <ErrorInputMessage invalidMessage={invalidMessage} invalid={invalid}/>
        </div>
      </div>
    );
  }
}

Input.defaultProps = {
  label: '',
  labelPosition: 'top',
  invalid: false,
  invalidMessage: '',
  className: '',
  children: null,
  onBlur: () => {},
  value: '',
  onChange: () => {},
};

Input.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelPosition: PropTypes.string,
  invalid: PropTypes.bool,
  invalidMessage: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.object,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  onChange: PropTypes.func,
};
