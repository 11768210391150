import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import './recipesContent.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import {MultiSelect} from 'react-multi-select-component';
import SwiperCore, {
  Mousewheel, Pagination, Keyboard,
} from 'swiper/core';

import {ContentActions} from '../../redux/actions';

SwiperCore.use([Mousewheel, Pagination, Keyboard]);

function RecipesPageContent(
  {
    color,
    isMobile,
    eventT,
    content: {
      titles,
      buttons,
      others,
    } = {},
    getRecipesContent,
    getRecipes,
    recipesContent: {
      // recipes = [],
      cooking_time: cookingTime = [],
      dish_types: dishTypes = [],
      event_types: eventTypes = [],
      products = [],
      category_product: categoryProduct = [],
    },
  },
) {
  const [filteredRecipes, setFilteredRecipes] = useState([]);
  const [filters, setFilters] = useState({
    products: [],
    cooking_time: [],
    dish_types: [],
    event_types: [],
    category_product: [],
    sort: [],
    menu_type: [],
  });

  const [needApplyFilters, setNeedApplyFilters] = useState(false);

  const ref = useRef();

  const showSortItems = () => {
    // if (!isMobile) {
    const items = document.querySelectorAll('.recipes-select-item');
    for (let i = 0; i < items.length; i++) {
      items[i].style.display = 'block';
    }
    // }
  };

  const applyFilters = (isClear = false) => {
    let formattedFilters = {};
    if (!isClear) {
      formattedFilters = { ...filters};
      if (formattedFilters.sort.length) {
        formattedFilters.sort = formattedFilters.sort[0];
      }
    }
    getRecipes(formattedFilters).then((data) => {
      const { recipes: newRecipes } = data;
      setFilteredRecipes(newRecipes);
    });
  };

  useEffect(() => {
    getRecipesContent();
    if (eventT) {
      setFilters({
        products: [],
        cooking_time: [],
        dish_types: [],
        event_types: [eventT],
        category_product: [],
        menu_type: [],
        sort: [],
      });
    }

    if (isMobile) {
      showSortItems();
    }
    const filterName = 'dish_types';
    const urlParams = new URLSearchParams(window.location.search);
    const value = urlParams.get(`${filterName}[]`);

    if (value && parseInt(filters.dish_types[0], 10) !== parseInt(value, 10)) {
      setFilters({
        products: [],
        cooking_time: [],
        dish_types: [parseInt(value, 10)],
        event_types: [],
        category_product: [],
        menu_type: [],
        sort: [],
      });
    }

    setNeedApplyFilters(true);
  }, []);

  useEffect(() => {
    if (needApplyFilters) {
      applyFilters();
      setNeedApplyFilters(false);
    }
  }, [needApplyFilters]);

  useEffect(() => {
    if (filters.sort.length > 0) {
      applyFilters();
    }
  }, [filters.sort]);

  const clearFilters = () => {
    setFilters({
      products: [],
      cooking_time: [],
      dish_types: [],
      event_types: [],
      category_product: [],
      menu_type: [],
      sort: [],
    });

    applyFilters(true);
  };

  const selectFilter = (filterName, selectedItems) => {
    setFilters({
      ...filters,
      [filterName]: selectedItems.map((item) => item.value),
    });
    if (selectedItems.length < 1) {
      setNeedApplyFilters(true);
    }
  };

  const recipeSlides = useMemo(() => filteredRecipes.map((item) => (
    <SwiperSlide style={filteredRecipes.length <= 1 ? {marginLeft: 0} : null} key={`recipe-${item.id}`}>
      <Link
        to={`/recipes/${item.slug}`}
        className="cover"
        style={{backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.77) 0%, rgba(0, 0, 0, 0) 30%,rgba(0, 0, 0, 0) 70%, rgba(0, 0, 0, 0.77) 100%), url(${item.preview_image})`}}
      >
        <div className="name">{item.name}</div>
        <div className="bottom-info">
          <div className="time">
            <div className="icon"/>
            <div className="value">{item.cooking_time} {others.minut}</div>
          </div>
          {/* <div className="calories">
            <div className="icon"/>
            <div className="value">{item.calories} {others.kcal}</div>
          </div> */}
        </div>
      </Link>
    </SwiperSlide>
  )), [filteredRecipes]);

  const emptySlide = (
    <SwiperSlide>
      <div className='empty-slide' style={{color: '#FFF'}}>{others.search_nothing_found}</div>
    </SwiperSlide>
  );

  const swiperElement = (
    <Swiper
      className="recipesSwiper"
      id="recipesSwiper"
      setWrapperSize="true"
      mousewheel
      observer
      observeParents
      keyboard={{
        enabled: true,
        onlyInViewport: true,
      }}
      // eslint-disable-next-line
      pagination={{
        el: '.swiper-pagination-bullets',
        type: 'bullets',
        clickable: true,
        dynamicBullets: true,
      }}
      breakpoints={{
        1800: {
          slidesPerView: 4.5,
        },
        1600: {
          slidesPerView: 4,
        },
        1400: {
          slidesPerView: 3.5,
        },
        1280: {
          slidesPerView: 3,
        },
        1000: {
          slidesPerView: 2.5,
          freeMode: true,
        },
        800: {
          slidesPerView: 2,
        },
        600: {
          slidesPerView: 1.7,
        },
        480: {
          slidesPerView: 1,
          spaceBetween: 50,
        },
        428: {
          slidesPerView: 1,
          spaceBetween: 50,
        },
        390: {
          slidesPerView: 1,
          spaceBetween: 50,
        },
        200: {
          slidesPerView: 1,
          spaceBetween: 20,
          freeMode: false,
        },
      }}
    >
      {recipeSlides.length > 0 ? recipeSlides : emptySlide}
      <div className="swiper-pagination-bullets"/>
    </Swiper>
  );

  const Renderer = (state) => (
    ({
      checked,
      option,
      onClick,
      disabled,
    }) => (
      option.label !== '_btn' ? (
        <div className={`item-renderer ${disabled && 'disabled'}`}>
          <div className="custom_wrapper">
            <input
              type="checkbox"
              onChange={onClick}
              checked={checked}
              tabIndex={-1}
              disabled={disabled}
              className="custom_input"
            />
            <div className="custom_input_wrapper" />
          </div>
          <span>{option.label}</span>
        </div>
      ) : (
        <div className="custom__clicker">
          <div className={isMobile ? 'custom__clicker_modal mobile' : 'custom__clicker_modal'}>
            <button type='button' onClick={() => { applyFilters(); ref.current.focus(); }}>
              {others.apply_filter}
            </button>
            <button
              className='filter-button'
              onClick={() => {
                state([]);
              }}
              type='button'
            >
              {others.clear_filter}
            </button>
          </div>
        </div>
      )
    )
  );
  const RadioRenderer = ({
    checked,
    option,
    onClick,
    disabled,
  }) => (
    <div className={`item-renderer ${disabled && 'disabled'}`}>
      <div className="custom_wrapper">
        <input
          type="radio"
          name="type"
          onChange={onClick}
          checked={checked}
          tabIndex={-1}
          disabled={disabled}
          className="custom_input"
        />
        <div className="custom_input_wrapper" />
      </div>
      <span>{option.label}</span>
    </div>
  );

  const dish = dishTypes.map((item) => (
    {value: item.id, label: item.name}
  ));
  dish.push({value: '', label: '_btn'});

  const event = eventTypes.map((item) => (
    {value: item.id, label: item.name}
  ));
  event.push({value: '', label: '_btn'});

  const menuList = [
    {value: 'ingredients', label: others.show_ingredients_mobile_filter},
    {value: 'dish-type', label: others.show_dish_type_mobile_filter},
    {value: 'preparing-time', label: others.show_cooking_time_mobile_filter},
    {value: 'recipes-sort', label: others.show_sort_mobile_filter},
  ];
  menuList.push({value: '', label: '_btn'});

  const ingredients = products.map((item) => ({
    value: item.id,
    label: item.name,
  }));
  ingredients.push({value: '', label: '_btn'});

  const category = categoryProduct.map((item) => ({
    value: item.id,
    label: item.name,
  }));
  category.push({value: '', label: '_btn'});

  const cookingTimeFix = [
    {name: 'до 30 минут', value: 1},
    {name: 'от 30 до 60 минут', value: 2},
    {name: 'более 60 минут', value: 3},
  ];
  const cookTime = cookingTimeFix.map((item) => ({
    value: item.value,
    label: item.name,
  }));
  cookTime.push({value: '', label: '_btn'});

  const sortTypes = [
    // {value: 'id|asc', label: others.sort_by_default},
    {value: 'cooking_time|asc', label: others.sort_by_cooking_time},
    {value: 'created_at|asc', label: others.sort_by_date},
    {value: 'name|asc', label: others.sort_by_name},
  ];

  const selectedProduct = ingredients.filter((item) => filters.products.includes(item.value));
  const selectedDishType = dish.filter((item) => filters.dish_types.includes(item.value));
  const selectedEventType = event.filter((item) => filters.event_types.includes(item.value));
  const selectedCookingTime = cookTime.filter((item) => filters.cooking_time.includes(item.value));
  const selectedSortType = sortTypes.filter((item) => filters.sort.includes(item.value));
  const selectedCategory = category.filter((item) => filters.category_product.includes(item.value));
  // const selectedSortType = sortTypes.filter((item) => filters.sort.includes(item.value.replace(/[^0-9]/g, '')));
  // const selectedMenuType = menuList.filter((item) => filters.menu_type.includes(item.value));
  return (
    <div className={`recipesWrapper recipesWrapper_${color}`}>
      {/* {filteredRecipes.length > 0 && (<div className="swiper-pagination-bullets"/>)} */}
      { !isMobile && (
        <a className="logo" href="/">
          <div/>
        </a>
      )}
      <h1 className="recipesWrapper_header">{titles.title_recipes}</h1>
      <div className="recipesWrapper_selects">
        <div
          style={isMobile && filters.menu_type.includes('category_product') ? {display: 'block', marginTop: '10px'} : {}}
          className="recipesWrapper_selects_select-item recipes-select-item category_product"
        >
          <MultiSelect
            options={category}
            value={selectedCategory}
            onChange={(items) => { selectFilter('category_product', items); }}
            className='recipes-select'
            hasSelectAll={false}
            disableSearch
            ItemRenderer={Renderer((items) => selectFilter('category_product', items))}
            overrideStrings={{
              selectSomeItems: others.category_product_filter,
            }}
            /* eslint-disable-next-line no-unused-vars */
            valueRenderer={(selected, _options) => (
              selected.length
                ? `${others.category_product_filter}: ${selected.length}`
                : others.category_product_filter
            )}
          />
        </div>
        <div
          style={isMobile && filters.menu_type.includes('dish-type') ? {display: 'block', marginTop: '10px'} : {}}
          className="recipesWrapper_selects_select-item recipes-select-item dish-type"
        >
          <MultiSelect
            options={dish}
            value={selectedDishType}
            onChange={(items) => selectFilter('dish_types', items)}
            className='recipes-select'
            hasSelectAll={false}
            disableSearch
            ItemRenderer={Renderer((items) => selectFilter('dish_types', items))}
            overrideStrings={{
              selectSomeItems: others.dish_type_filter,
            }}
            /* eslint-disable-next-line no-unused-vars */
            valueRenderer={(selected, _options) => (
              selected.length
                ? `${others.dish_type_filter}: ${selected.length}`
                : others.dish_type_filter
            )}
          />
        </div>
        <div
          style={isMobile && filters.menu_type.includes('event-type') ? {display: 'block', marginTop: '10px'} : {}}
          className="recipesWrapper_selects_select-item recipes-select-item event-type"
        >
          <MultiSelect
            options={event}
            value={selectedEventType}
            onChange={(items) => selectFilter('event_types', items)}
            className='recipes-select'
            hasSelectAll={false}
            disableSearch
            ItemRenderer={Renderer((items) => selectFilter('event_types', items))}
            overrideStrings={{
              selectSomeItems: others.event_type_filter,
            }}
            /* eslint-disable-next-line no-unused-vars */
            valueRenderer={(selected, _options) => (
              selected.length
                ? `${others.event_type_filter}: ${selected.length}`
                : others.event_type_filter
            )}
          />
        </div>
        <div
          style={isMobile && filters.menu_type.includes('preparing-time') ? {display: 'block', marginTop: '10px'} : {}}
          className="recipesWrapper_selects_select-item recipes-select-item preparing-time"
        >
          <MultiSelect
            options={cookTime}
            value={selectedCookingTime}
            onChange={(items) => selectFilter('cooking_time', items)}
            className='recipes-select'
            hasSelectAll={false}
            disableSearch
            ItemRenderer={Renderer((items) => selectFilter('cooking_time', items))}
            overrideStrings={{
              selectSomeItems: others.cooking_time_filter,
            }}
            /* eslint-disable-next-line no-unused-vars */
            valueRenderer={(selected, _options) => (
              selected.length
                ? `${others.cooking_time_filter}: ${selected.length}`
                : others.cooking_time_filter
            )}
          />
        </div>
        <div
          style={isMobile && filters.menu_type.includes('recipes-sort') ? {display: 'block', marginTop: '10px'} : {}}
          className="recipesWrapper_selects_select-item recipes-select-item recipes-sort"
        >
          <MultiSelect
            options={sortTypes}
            value={selectedSortType}
            onChange={(value) => {
              selectFilter('sort', value.length ? [value[value.length - 1]] : []);
            }}
            className='recipes-select'
            hasSelectAll={false}
            disableSearch
            ItemRenderer={RadioRenderer}
            overrideStrings={{
              selectSomeItems: others.sort_filter,
            }}
          />
        </div>
        <button ref={ref} type="button" style={{width: '1px', opacity: '0', overflow: 'hidden'}} className="recipesWrapper_selects_button" onClick={() => applyFilters()}>{buttons.button_find_recipes}</button>

        { (!!filters.products.length || !!filters.cooking_time.length || !!filters.dish_types.length || !!filters.event_types.length || !!filters.sort.length)
          && (
            <button
              type="button"
              className="recipesWrapper_selects_clear-button filter-button-clear"
              onClick={clearFilters}
            >
              <span className='clear_icon' />{buttons.button_clear_filters}
            </button>
          )}
      </div>
      <div className="recipesWrapper_content">
        { swiperElement }
      </div>
    </div>
  );
}

export default connect(
  (state) => ({
    content: state.ContentReducer.content,
    isMobile: state.ResizeReducer.isMobile,
    recipesContent: state.ContentReducer.recipesContent,
  }),
  (dispatch) => ({
    getRecipes: (filters) => ContentActions.getRecipes(filters)(dispatch),
    getRecipesContent: () => ContentActions.getRecipesContent()(dispatch),
  }),
)(RecipesPageContent);
