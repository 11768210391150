import React from 'react';
import { connect } from 'react-redux';
import {
  Link,
  Route,
  Switch,
  withRouter,
} from 'react-router-dom';
import './petruhaContent.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Keyboard,
} from 'swiper/core';
import ReactHtmlParser from 'react-html-parser';
import { VideoPopupActions } from '../../redux/actions';
import ExpertModal from '../ExpertPage/ExpertModal';
import InterviewModal from '../InterviewPage/InterviewModal';

SwiperCore.use([Keyboard]);
class PetruhaContent extends React.Component {
  constructor(props) {
    super(props);
    const {
      content: {
        petruhaOnline: {
          productionStages = [],
          interviews = [],
          experts = [],
          expertProfessions = [],
        } = {},
      } = {},
    } = this.props;
    this.state = {
      productionStages,
      currentStage: productionStages.length ? productionStages[0] : null,
      interviews,
      experts,
      expertProfessions,
      chosenProfession: 'all',
      currentInterview: interviews.length ? interviews[0] : null,
    };
  }

  componentDidMount() {
    const { hash } = this.props;

    if (hash) {
      const block = document.querySelector(hash);

      if (block) {
        block.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
    }
  }

  showExperts = (value) => {
    this.setState({
      chosenProfession: value,
    });
  }

  changeStage = (stageId) => {
    const { productionStages } = this.state;
    this.setState({
      currentStage: productionStages.find((stage) => stage.id === stageId),
    });
  }

  changeInterview = (interviewId) => {
    const { interviews } = this.state;
    this.setState({
      currentInterview: interviews.find((interview) => interview.id === interviewId),
    });
  }

  render() {
    const {
      chosenProfession,
      currentStage,
      productionStages,
      interviews,
      experts,
      expertProfessions,
      showVideoPopupInterview,
      currentInterview,
    } = this.state;
    const {
      openPopup,
      content: {
        buttons,
        titles,
      } = {},
      // location: {
      //   pathname,
      // },
      isMobile,
    } = this.props;
    // const expertModalWindowOpen = pathname.indexOf('experts') > 1 || pathname.indexOf('interview') > 1;
    const howSlides = productionStages.map((item) => (
      <SwiperSlide key={`stages-${item.id}`}>
        <div className={item === currentStage ? 'how-slide-wrapper current-slide' : 'how-slide-wrapper'} onClick={() => this.changeStage(item.id)}>
          <div className="icon" style={{ backgroundImage: `url(${item.image})` }} />
          <div className="header">{item.title}</div>
          <div className="text">{item.preview_text}</div>
        </div>
      </SwiperSlide>
    ));

    const howSlidesMobile = productionStages.map((item) => (
      <SwiperSlide key={`stages-${item.id}`}>
        <div className={item === currentStage ? 'how-slide-wrapper current-slide' : 'how-slide-wrapper'}>
          <div className="text-content">
            <div className="text">{item.text}</div>
          </div>
          <div
            className="image"
            style={{ backgroundImage: `url(${item.preview_image}` }}
            onClick={(e) => {
              e.stopPropagation();
              if (item.video) {
                openPopup(item.video);
              }
            }}
          >
            {
              item.video ? (
                <div className="play-icon" />
              ) : null
            }
          </div>
          <div className="header">{item.title}</div>
          <div className="text">{item.preview_text}</div>
        </div>
      </SwiperSlide>
    ));

    const interviewSlides = interviews.map((item) => (
      <SwiperSlide key={`interviews-${item.id}`}>
        <div className="interview-slide-wrapper" onClick={() => this.changeInterview(item.id)}>
          {!isMobile ? (
            <>
              <div
                className="icon"
                style={{ backgroundImage: `url(${item.preview_image})` }}
                onClick={() => {
                  if (item.video) {
                    openPopup(item.video);
                  }
                }}
              >
                <div className="name first">{item.first_name}</div>
                <div className="name second">{item.second_name}</div>
                <div className="position">{item.profession}</div>
                {
                  item.video && (
                    <div className="play-icon" />
                  )
                }
              </div>
              <div className="text-wrapper">
                <div className="quotes" />
                <div className="text">{item.title}</div>
                <div className="quotes_bottom" />
                <Link className='interview-link' to={`/petruha-online/interview/${item.id}`}>
                  {buttons.button_read_interview}
                </Link>
              </div>
            </>
          ) : (
            <>
              <div
                className="icon"
                style={{ backgroundImage: `url(${item.preview_image})` }}
                onClick={() => {
                  if (item.video) {
                    openPopup(item.video);
                  }
                }}
              >
                {
                  item.video && (
                    <div className="play-icon" />
                  )
                }
              </div>
              <div className='fio'>
                <div className="name first">{item.first_name}</div>
                <div className="name second">{item.second_name}</div>
              </div>
              <div className="position">{item.profession}</div>
              <div className="text-wrapper">
                <div className="text">{item.title}</div>
                <Link className='interview-link' to={`/petruha-online/interview/${item.id}`}>
                  {buttons.button_read_interview}
                </Link>
              </div>
            </>
          )}
        </div>
      </SwiperSlide>
    ));

    const expertsSlides = experts.filter((item) => chosenProfession === 'all' || item.profession_id === chosenProfession).map((item) => (
      <SwiperSlide key={`experts-${item.id}`}>
        <div
          className="swiper-slide-wrapper"
          style={{ backgroundImage: `url(${item.photo}` }}
        >
          <Link className='expert-link' to={`/petruha-online/experts/${item.id}`}>
            <div className='firstName'>{item.first_name}</div>
            <div className='secondName'>{item.second_name}</div>
            <div className='position'>{item.profession}</div>
          </Link>
        </div>
      </SwiperSlide>
    ));

    const expertsList = (
      <>
        <div className={chosenProfession === 'all' ? 'item clicked' : 'item'} onClick={() => this.showExperts('all')}>{buttons.button_all}</div>
        {
          expertProfessions.map((item) => (
            <div key={`professions-${item.id}`} className={chosenProfession === item.id ? 'item clicked' : 'item'} onClick={() => this.showExperts(item.id)}>{item.name}</div>
          ))
        }
      </>
    );
    const swiperElement = (
      <Swiper
        className="interviewSwiper"
        id="interviewSwiper"
        setWrapperSize="true"
        pagination={isMobile && { clickable: true }}
        eslint-disable-next-line
        loop
        onAfterInit={(swiper) => {
          swiper.update();
          swiper.mousewheel.enable();
        }}
        // slidesPerView="1"
        keyboard={{
          enabled: true,
          onlyInViewport: true,
        }}
        breakpoints={{
          1800: {
            slidesPerView: 5.51,
            spaceBetween: 30,
          },
          1600: {
            slidesPerView: 4.5,
          },
          1400: {
            slidesPerView: 3.5,
          },
          1200: {
            slidesPerView: 3,
          },
          1000: {
            slidesPerView: 1,
          },
        }}
      >
        {isMobile ? howSlidesMobile : howSlides}
      </Swiper>
    );

    const swiperElement2 = (
      <Swiper
        className="interviewSwiper"
        id="interviewSwiper"
        loop="true"
        setWrapperSize="true"
        onAfterInit={(swiper) => {
          swiper.update();
          // swiper.mousewheel.enable();
        }}
        pagination={isMobile && { clickable: 'true' }}
        // slidesPerView="1.3"
        breakpoints={{
          1600: {
            slidesPerView: 1.3,
          },
          300: {
            slidesPerView: 1,
          },
        }}
      >
        {interviewSlides}
      </Swiper>
    );

    const swiperElement3 = (
      <Swiper
        className="expertsSwiper"
        id="expertsSwiper"
        setWrapperSize="true"
        loop={expertsSlides.length > 2}
        pagination={isMobile && { clickable: 'true' }}
        onAfterInit={(swiper) => {
          swiper.update();
        }}
        // slidesPerView="3.2"
        breakpoints={{
          700: {
            slidesPerView: '1',
          },
          1200: {
            slidesPerView: '2',
          },
          1600: {
            slidesPerView: '2.5',
          },
          1900: {
            slidesPerView: '3.2',
          },
        }}
      >
        {expertsSlides}
      </Swiper>
    );

    return (
      <div
        className='petruhaOnlineWrapper'
      >
        <Switch>
          <Route
            path='/petruha-online/experts/:id'
            render={() => (<ExpertModal isMobile={isMobile} interviews={interviews} experts={experts} expertsTitle={titles.title_other_experts} />)}
          />
          <Route
            path='/petruha-online/interview/:id'
            render={() => (<InterviewModal isMobile={isMobile} interviews={interviews} experts={experts} expertsTitle={titles.title_other_experts} />)}
          />
        </Switch>
        <Link to='/' className="petruhaOnlineWrapper_logo" />
        {/* <div style={expertModalWindowOpen && isMobile ? { display: 'none' } : {}} className="petruhaOnlineWrapper_how" id='productionStages'> */}
        <div className="petruhaOnlineWrapper_how" id='productionStages'>
          <div className="content">
            {
              currentStage && !isMobile && (
                <>
                  <div className="text-content">
                    <h1 className="header">{titles.title_how_make}</h1>
                    <div className="text">{currentStage.text}</div>
                  </div>
                  <div
                    className="image"
                    style={{ backgroundImage: `url(${currentStage.preview_image}` }}
                    onClick={() => {
                      if (currentStage.video) {
                        openPopup(currentStage.video);
                      }
                    }}
                  >
                    {
                      currentStage.video ? (
                        <div className="play-icon" />
                      ) : null
                    }
                  </div>
                </>
              )
            }
          </div>
          {isMobile && (<h1 className="header">{titles.title_how_make}</h1>)}
          <div className="how-swiper-wrapper">
            {swiperElement}
          </div>
        </div>
        {/* <div style={expertModalWindowOpen && isMobile ? { display: 'none' } : {}} className="petruhaOnlineWrapper_interview" id='interviews'> */}
        <div className="petruhaOnlineWrapper_interview" id='interviews'>
          <div className="header">{titles.title_interview}</div>
          <div className="interview-swiper-container">
            {swiperElement2}
          </div>
          {
            showVideoPopupInterview
            && (
              <div
                className="videoPopup"
                onClick={() => this.closeVideoPopupInterview()}
              >
                {currentInterview.video
                  ? ReactHtmlParser(currentInterview.video)
                  : (<div className='empty-video'>К сожалению, видео отсутствует</div>)}
              </div>
            )
          }
        </div>
        {/* <div style={expertModalWindowOpen && isMobile ? { display: 'none' } : {}} className="petruhaOnlineWrapper_experts" id='experts'> */}
        <div className="petruhaOnlineWrapper_experts" id='experts'>
          <div className="header">{titles.title_experts}</div>
          <div className="content-wrapper">
            {!isMobile && (
              <div className="list">
                {expertsList}
              </div>
            )}
            <div className="experts-swiper-wrapper">
              {swiperElement3}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    isMobile: state.ResizeReducer.isMobile,
    content: state.ContentReducer.content,
  }),
  (dispatch) => ({
    openPopup: (url) => {
      dispatch(VideoPopupActions.openVideoPopup(url));
    },
  }),
)(withRouter(PetruhaContent));
