import { createBrowserHistory } from 'history';

const History = (() => {
  let instance = null;

  const init = () => {
    const browserHistory = createBrowserHistory();

    return {
      history: browserHistory,
    };
  };

  return {
    getInstance: () => {
      if (!instance) {
        instance = init();
      }
      return instance;
    },
  };
})();

export default History;
