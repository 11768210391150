import baseAxios from 'axios';

// import ReduxStoreService from '../services/ReduxStoreService';
// import { extractErrorDataFromResponseApi } from '../utils/apiUtils';

// import { ActionsConstants } from '../constants';

const domain = window.location.hostname;
let apiUrl;
switch (domain) {
  case process.env.REACT_APP_DOMAIN_RU:
    apiUrl = process.env.REACT_APP_API_URL_RU;
    break;
  case process.env.REACT_APP_DOMAIN_BY:
    apiUrl = process.env.REACT_APP_API_URL_BY;
    break;
  case process.env.REACT_APP_DOMAIN_COM:
    apiUrl = process.env.REACT_APP_API_URL_COM;
    break;
  default:
    apiUrl = process.env.REACT_APP_API_URL_BY;
    break;
}

const api = baseAxios.create({
  baseURL: apiUrl,
  withCredentials: true,
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    // const { error: errorMessage, payload = {} } = extractErrorDataFromResponseApi(error);
    if (error.response.status === 401) {
      // ReduxStoreService.getInstance().store.dispatch({
      //   type: ActionsConstants.Auth.SIGN_OUT,
      // });
      return new Promise(() => {});
    }
    return Promise.reject(error);
  },
);

export default api;
