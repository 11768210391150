import React, {useEffect} from 'react';
import { connect } from 'react-redux';
// import { useParams } from 'react-router-dom';
import RecipesContent from '../../components/RecipesContent';
import BasePageLayout from '../../components/layouts/BasePageLayout';
import SEO from '../../components/SEO';

const RecipesPage = ({isMobile, lang}) => { // match: {params: { filter }},
  const titleRu = 'Рецепты блюд из мяса птицы — пошаговые рецепты с фото от бренда Петруха';
  const titleEn = 'Chicken recipes - step by step recipes with videos and photos from Petruha';
  // const { filter } = useParams();
  const {search} = window.location;
  const params = new URLSearchParams(search);

  const eventT = params.get('event');

  useEffect(() => {
    document.title = lang === 'en' ? titleEn : titleRu;
    // if (filter) console.log(filter);
  });
  return (
    <div>
      <SEO
        description={lang === 'en' ? titleEn : titleRu}
        ogDescription={lang === 'en' ? titleEn : titleRu}
        keywords='Петруха, рецепты, курица'
        ogTitle={lang === 'en' ? titleEn : titleRu}
        title={lang === 'en' ? titleEn : titleRu}
      />
      <BasePageLayout
        overflowProp={isMobile ? 'auto' : ''}
        displayProp={isMobile ? 'block' : ''}
        marginProp='-5px'
        foot
      >
        <RecipesContent eventT={eventT}/>
      </BasePageLayout>
    </div>
  );
};

export default connect(
  (state) => ({
    isMobile: state.ResizeReducer.isMobile,
    height: state.ResizeReducer.height,
  }),
)(RecipesPage);
